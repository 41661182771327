.contact {
  &__container {
    @include container();
    justify-content: space-between;
    @media (max-width: 1700px) {
      padding-right: 80px;
    }
    @media (max-width: 1280px) {
      padding-right: 20px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
    h1 {
      margin-bottom: 80px;
      font-weight: 700;
      font-size: 54px;
      line-height: 65px;
      font-family: CormorantGaramond-Bold;
      letter-spacing: .8px;
      @media (max-width: 1700px) {
        font-size: 48px;
        line-height: 58px;
      }
      @media (max-width: 1024px) {
        font-size: 26px;
        line-height: 34px;
      }
      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }
  &__addresses {
    @media (max-width: 768px) {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &__item {
    margin-bottom: 70px;
    width: 330px;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 1024px) {
      width: 280px;
    }
    @media (max-width: 768px) {
      margin-bottom: 40px;
    }
    h3 {
      margin-bottom: 20px;
    }
    span {
      padding-left: 30px;

      &:last-child {
        margin-top: 20px;
      }
      svg {
        margin-left: -30px;
        margin-right: 10px;
      }
      a {
        color: inherit;
      }
    }
  }
  &__consultation {
    width: 640px;
    @media (max-width: 1170px) {
      width: 100%;
    }
    h3 {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 30px;
      color: $gray-additional;
      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }
  &__map {
    margin-top: 160px;
    width: 100%;
    height: 600px;
    @media (max-width: 1024px) {
      height: 400px;
      margin-top: 80px;
    }
  }
}
.form-consul {
  &__inner  {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
    input {
      height: 60px;
      border: 1px solid #D9BEA4;
      padding-left: 40px;
      margin-bottom: 20px;
      &::placeholder {
        color: $gray-color;
      }
      @media (max-width: 1024px) {
        padding-left: 17px;
      }
    }
    textarea {
      border: 1px solid #D9BEA4;
      margin-bottom: 40px;
      height: 180px;
      padding-left: 40px;
      padding-top: 24px;
      margin-top: 20px;
      @media (max-width: 480px) {
        margin-top: 0;
      }
      &::placeholder {
        color: $gray-color;
      }
      @media (max-width: 1024px) {
        padding-left: 17px;
        padding-top: 12px;
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 510px) {
      flex-direction: column;
    }
  }
  &__input {
    width: 100%;
    margin-right: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1170px) {
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
    .form-const__error {
      margin-top: 5px;
    }
    input {
      height: 60px;
      border: 1px solid #D9BEA4;
      padding-left: 40px;
      &::placeholder {
        color: $gray-color;
      }
      @media (max-width: 1024px) {
        padding-left: 17px;
      }
    }
  }
  button {
    display: block;
    margin-left: auto;
    @media (max-width: 510px) {
      margin: 0 auto;
    }
  }

}