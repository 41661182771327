.breadcrumb {
  padding-bottom: 60px;
  margin-top: 30px;
  br {
    display: none;
  }
  @media (max-width: 1024px) {
padding-bottom: 24px;
        padding-top: 31px;
        margin-top: 55px;
  }
  &.d-flex-sm {
    padding-left: 20px;
    padding-right: 20px
  }
  &__container {
    @include container();

  }
  &__items {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__item {
    margin-right: 50px;
    position: relative;
    @media (max-width: 1024px) {
      margin-right: 30px;
    }
    &:before {
      content: "";
      background: url("../../img/icons/ellipse-bread.svg");
      width: 6px;
      height: 6px;
      position: absolute;
      top: 18px;
      left: -25px;
      @media (max-width: 1024px) {
        top: 12px;
        left: -16px;
      }
    }
    a {
      font-size: 14px;
      line-height: 40px;
      color: $gray-additional;
      text-decoration: none !important;
      @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
    &:last-child {
      margin-right: 0;

      a {
        cursor: auto;
        color: $gray-color;
      }
    }
  }
}
.container-full {
  .breadcrumb {
    margin-top: 0;
  }
  .breadcrumb__container {
    padding: 0
  }
}