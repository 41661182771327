.header {
  width: 100%;
  transition: 2s all ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05);


  @media (max-width: 1024px) {
    box-shadow: none;
    border-bottom: 1px solid $main-color;
  }

  &--fixed {
    background: $white;
  }

  &__container {
    @include container();
    margin: 0 auto;
    height: 128px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1700px) {
      height: 100px;
    }

    @include md(height, 67px);
  }

  &__logo {
    @media (max-width: 1024px) {
      display: flex;
    }

    img {
      vertical-align: top;
      @media (max-width: 1700px) {
        width: 240px;
      }

      @include lg(width, 176px);

      //width: 100%;
      @include sm(width, 157px)
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: $main-color;
    margin: auto 40px;
    @include md(position, absolute);
    @include md(bottom, 0);
    @include md(left, 0);
    @include md(margin, 0);
  }

  &__call {
      @media (max-width: 1365px) {
        font-size: 15px
      }
      @media (max-width: 1180px) {
        margin-right: 20px;
      }
    span {
      line-height: 25px;

      a {
        color: inherit;
      }

      &:first-child {
        position: relative;
        padding-left: 44px;
        display: block;
      @media (max-width: 1366px) {
    padding-left: 30px;
  }
        &:before {
          content: "";
          background-image: url(../../img/icons/call.svg);
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
        }
      }
    }

    &--mobile {
      display: none;

      @media (max-width: 1024px) {
        display: flex;
        margin-top: -40px;
        margin-bottom: 15px;
        position: relative;
        z-index: 102;
        margin-left: 40px;

        span {
          a {
            font-size: 18px;
          }
        }
      }

      @media (max-width: 380px) {
        flex-direction: column;

        span {
          &:last-child {
            margin-left: 45px;
            margin-top: 10px;
          }

          a {
            font-size: 18px;
          }
        }
      }
    }
  }

  &__nav {
    position: fixed;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    transition: all .3s ease-in;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 134px;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 100;
    opacity: 0;

    @media (max-width: 1024px) {
      width: 100vw;
      height: 100%;
      padding: 0;
      top: 54px;
      background-image: url("../../img/menu-dg-mob.svg");
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100vh;
      background: $main-color;
    }

    &:after {
      content: "";
      position: absolute;
      left: -1920px;
      width: 100%;
      height: 100%;
      transition: 1s all ease-in-out;
    }

    &.active {
      opacity: 1;
      display: flex;
      left: 0;
      z-index: 100;
    }

    &.activeH {
      &:after {
        content: "";
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url("../../img/menu-dg.svg") #fff;
        ;
        transition: 1s all ease-in-out;
      }

      &-inner {}

      .lang {
        display: none;
        margin-bottom: 30px;
        justify-content: center;
        line-height: 60px;
        background-color: $black-color;

        @media (max-width: 1024px) {
          display: flex;
          position: relative;
          z-index: 101;
        }

        &__item {
          margin-right: 30px;

          a {
            color: $additional-color;
            font-size: 16px;
            text-transform: uppercase;
          }

          &--active {
            a {
              color: $white;
              font-weight: 700;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .socials {
        display: none;
        flex-direction: column;
        margin-left: 40px;
        margin-bottom: 40px;

        @media (max-width: 1024px) {
          display: flex;
          position: relative;
          z-index: 101;
        }

        span {
          margin-bottom: 10px;
          color: $gray-color;
        }

        ul li {
          margin-right: 30px;

          a img {
            width: 15px;
            height: 15px;
          }
        }
      }

    }

    &-inner {
      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        padding-bottom: 40px;
      }

      @media (max-width: 480px) {
        background-image: url("../../img/img-dg.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top right;
        position: relative;
        z-index: 999;
      }
    }

    .menu-desc {
      width: calc(100% - 100px);
      justify-content: flex-end;
      align-items: center;
      position: relative;
      z-index: 100;

      @media (max-width: 1170px) {
        display: none;
      }

      &__item {
        position: absolute;
        right: 0;
        opacity: 0;
        transition: 1s all ease-in-out;
        z-index: 0;
        height: 450px;
        max-width: 880px;
        max-height: 450px;

        @media(max-width: 1700px) {
          width: 670px;
          height: 360px;
        }

        &--active {
          display: flex;
          opacity: 1;
        }

        &--activeS {
          display: flex;
          opacity: 1;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }


  }

  &-info {
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 101;

    @media (max-width: 1700px) {
      bottom: 10px;
    }

    @include md(display, none);

    &--mob-none {
      @media (max-width: 1700px) {
        display: none;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      text-align: right;
      color: $main-color;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: inherit;
      }
    }
  }

  &__menu {
    @media (max-width: 1365px) {
      font-size: 15px
    }
    ul {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
    }

    a {
      margin: 0 16px;
      color: $black-color
    }

    @media (max-width: 1180px) {
      display: none
    }
  }
}

.menu {
  margin-left: 130px;
  margin-top: 168px;
  position: relative;
  z-index: 101;

  @media(max-width: 1700px) {
    margin-top: 30px;
    margin-left: 80px;
  }

  @media(max-width: 1280px) {
    margin-left: 30px;
  }

  @media(max-width: 1024px) {
    margin: 0;
    padding-bottom: 40px;
    width: 100%;
    padding-left: 40px;
  }

  &__item {
    transition: all 1s ease;
    position: relative;
    left: 0;
    transition: all 1s ease;

    &:hover {
      padding-left: 60px;

      @media (max-width: 1024px) {
        padding-left: 0;
      }
    }

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 34px;
    }

    @media (max-width: 1024px) {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: 60px;
      line-height: 130px;
      font-family: $font-CormorantGaramond-Bold;
      color: $black-color;

      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &--submenu {
      position: relative;
      left: 61px;
      transition: all 1s ease;
      padding-left: 0;

      &:hover {
        padding-left: 0;
      }

      @media (max-width: 1024px) {
        left: 0;
        position: unset;
      }
    }

    &--service {
      position: relative;

      @media (max-width: 1024px) {
        position: unset;
      }
    }
  }

  .indicator {
    background-color: $main-color;
    position: absolute;
    z-index: 0;
    top: 65px;
    width: 35px;
    height: 2px;
    transition: all 1s ease;
    opacity: 0;

    &--active {
      top: 195px;
      opacity: 1;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.second-menu {
  position: absolute;
  top: 0;
  left: 300px;
  display: none;
  transition: 1s all ease-in-out;
  z-index: 102;
  width: max-content;

  @media(max-width: 1024px) {
    position: unset;
    padding-left: 20px;
  }

  &__item {
    position: relative;
    padding-left: 60px;
    @include md(padding-left, 0);
    @include md(margin-bottom, 15px);

    &:hover {
      &:before {
        content: "";
        position: absolute;
        top: 35px;
        left: 0;
        width: 35px;
        height: 2px;
        background-color: $main-color;

        @media (max-width: 1024px) {
          content: "\00B0";
          color: $main-color;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          font-size: 20px;
        }
      }

      .third-menu {
        display: flex;
      }
    }

    &--active {
      &:before {
        content: "";
        position: absolute;
        top: 35px;
        left: 0;
        width: 35px;
        height: 2px;
        background-color: $main-color;

        @media (max-width: 1024px) {
          content: "\00B0";
          color: $main-color;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          font-size: 20px;
        }
      }
    }

    a {
      font-size: 24px;
      line-height: 70px;
      color: $black-color;
      font-family: $font-OpenSans-Regular;

      @media(max-width: 1024px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

.third-menu {

  display: none;

  @media(max-width: 1024px) {
    margin-top: 30px;
    flex-direction: column;
  }

  &__inner {
    margin-right: 15px;
    margin-left: 15px;
    flex-wrap: wrap;
    align-items: stretch;
    width: 450px;

    @media (max-width: 1024px) {
      flex-wrap: unset;
      flex-direction: column;
    }
  }

  &__item {
    width: 215px;
    margin-bottom: 14px;
    line-height: 22px;

    @media (max-width: 1024px) {
      width: 230px;
    }

    &:nth-child(2n+1) {
      margin-right: 20px;
    }

    &--active {
      a {
        color: $main-color;
      }
    }

    a {
      font-size: 16px;
      line-height: 22px;
      color: $black-color;
      font-family: $font-OpenSans-Light;

      &:hover {
        color: $main-color;
      }

      @media(max-width: 1024px) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}

.icon-menu {
  display: none;
  width: 34px;
  height: 34px;
  cursor: pointer;
  text-align: center;
  padding-top: 3px;
  position: absolute;
  top: 50%;

  @media(max-width: 1180px) {
    display: block;
    order: 3;
    position: relative;
    top: 5px;
  }

  &__title {
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
    color: $black-color;
    position: relative;
  }

  &__burger {
    position: relative;
    width: 30px;
    height: 17px;
    margin: 0 auto 2px;
    cursor: pointer;
    transition: .5s ease-in-out;
    transform: rotate(0deg);

    span {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      transition: .25s ease-in-out;
      transform: rotate(0deg);
      opacity: 1;
      border-radius: 2px;
      background: $main-color;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
        width: 17px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1024px) {
          width: 25px;
        }
      }

      &:nth-child(4) {
        top: 16px;
      }
    }
  }

  &.active {
    .icon-menu__burger {
      span {
        &:nth-child(1) {
          top: 18px;
          left: 50%;
          width: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 18px;
          left: 50%;
          width: 0;
        }
      }
    }
  }
}

.menu .menu__item:nth-child(1):hover~.indicator,
.menu .menu__item:nth-child(1):active~.indicator {
  top: 65px;
  opacity: 1;
}

.menu .menu__item:nth-child(2):hover~.indicator,
.menu .menu__item:nth-child(2):active~.indicator {
  top: 195px;
  opacity: 1;
}

.menu .menu__item:nth-child(3):hover~.indicator,
.menu .menu__item:nth-child(3):active~.indicator {
  top: 330px;
  opacity: 1;
}

.menu .menu__item:nth-child(4):hover~.indicator,
.menu .menu__item:nth-child(4):active~.indicator {
  top: 460px;
  opacity: 1;
}

.menu .menu__item:nth-child(5):hover~.indicator,
.menu .menu__item:nth-child(5):active~.indicator {
  top: 590px;
  opacity: 1;
}


.header-btn {
  margin: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1365px) {
    font-size: 15px;
    width: 240px;
        height: 50px;
  }
  @media (max-width: 1180px) {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    display: none
  }
}

.language-switcher {
  margin: 0 15px;

  @media (max-width: 1180px) {
    margin: 0 25px;
  }

  a {
    font-family: $font-OpenSans-Bold;
    color: $main-color;
    line-height: 111.111%;
    letter-spacing: 0.32px;
    text-transform: uppercase;
  }

  .lang__item--active {
    display: none;
  }
}

.menu-has-children {
  &>a {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:after {
      content: '';
      height: 5px;
      width: 5px;
      display: inline-block;
      border: 1px solid $black-color;
      border-left-width: 0;
      border-top-width: 0;
      display: block;
      transform-origin: 0 0;
      margin: -1px 0 0 10px;
      transform: rotate(45deg);
    }
  }
}

.open {
  &>.menu-submenu {
    transform: translateY(0);
  }
}

.open-submenu {
  &>.menu-submenu {
    transform: translateY(0);
  }
}

.menu-submenu__list {
  ul {
    margin: 0 -40px;
  }

  li {
    margin: 0 40px;
  }

  .item-menu {
    display: inline-flex;
    color: inherit;
    cursor: pointer;
    transition: color .8s ease;

    &:hover {
      color: $main-color;

      &:before {
        border-color: $main-color
      }
    }

    &:before {
      margin: 5px 10px 0 0;
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #fff;
      flex-shrink: 0;
      transition: border-color .8s ease;
    }
  }
}

.menu-submenu {
  position: fixed;
  top: 128px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2b2b2b;
  background-image: url(../../img/submenu.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% auto;
  color: #fff;
  z-index: 120;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(-200%);
  transition: transform 1s ease;

  @media (max-width: 1700px) {
    top: 100px;
  }

  .container {
    padding-top: 8.2vh;
    padding-bottom: 8vh;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
    display: flex;
  }

  .section-header {
    padding: 0 5.2%;
    flex-shrink: 0
  }

  .section-header__line {
    transform: none;
  }

  &__list {
    margin-top: 4vh;
    margin-bottom: 12vh;
    flex-grow: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      flex-wrap: wrap;
    }

    &-3 {
      margin-bottom: 0;

      li {
        width: calc(25% - 80px);
        margin-bottom: 37px;
      }
    }
  }

  &_sub {
    z-index: 121;
    position: absolute;
    top: 0;
    overflow: auto;
  }

  .h2-title {
    display: flex;
    align-items: center;

    i {
      margin: 0 20px 0 -82px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'%3E%3Cpath d='M30.5307 8.96932C30.6005 9.03898 30.6558 9.12169 30.6935 9.21274C30.7313 9.30379 30.7507 9.40139 30.7507 9.49995C30.7507 9.59851 30.7313 9.69611 30.6935 9.78716C30.6558 9.8782 30.6005 9.96092 30.5307 10.0306L16.0614 24.4999L30.5307 38.9693C30.6715 39.1101 30.7505 39.3009 30.7505 39.4999C30.7505 39.699 30.6715 39.8898 30.5307 40.0306C30.39 40.1713 30.1991 40.2504 30.0001 40.2504C29.8011 40.2504 29.6102 40.1713 29.4695 40.0306L14.4695 25.0306C14.3997 24.9609 14.3444 24.8782 14.3067 24.7872C14.2689 24.6961 14.2495 24.5985 14.2495 24.4999C14.2495 24.4014 14.2689 24.3038 14.3067 24.2127C14.3444 24.1217 14.3997 24.039 14.4695 23.9693L29.4695 8.96932C29.5391 8.89959 29.6218 8.84427 29.7129 8.80653C29.8039 8.76879 29.9015 8.74936 30.0001 8.74936C30.0987 8.74936 30.1963 8.76879 30.2873 8.80653C30.3784 8.84427 30.4611 8.89959 30.5307 8.96932Z' fill='white'/%3E%3C/svg%3E");
      display: block;
      height: 48px;
      width: 48px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      flex-shrink: 0;
      transition: transform .8s ease;
    }

    &:hover {
      i {
        transform: translateX(-15px);
      }
    }
  }
}

.header-close {
  padding: 26px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0
}

.close-menu {
  background-image: url(../../img/icons/close-menu.svg);
  display: block;
  height: 48px;
  width: 48px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  transition: transform 1s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-15px);
  }

}