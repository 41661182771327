.cases {
  padding-bottom: 160px;
  &__container {
    @include container();

    @media (max-width: 1700px) {
      padding-right: 80px;
    }
    @media (max-width: 1280px) {
      padding-right: 20px;
    }

  }
  &__btn {
    width: 340px;
    height: 60px;
    border: 2px solid $main-color;
    background-color: #fff;
    color: $main-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 1024px) {
      margin-top: 40px;
    }
    @media (max-width: 360px) {
      width: 280px;
      height: 50px;
    }

  }
  &__info {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 42%;
      right: -25px;
      background: url("../../img/icons/Chevron-Right.svg") no-repeat;
      height: 9px;
      width: 14px;
      transition: .5s all ease-in-out;
    }
    &--active {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
  &__title {
    margin-bottom: 35px;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    font-family: CormorantGaramond-Bold;
    letter-spacing: .8px;
      @media(max-width: 1700px) {
        font-size: 48px;
        line-height: 58px;
      }
      @media(max-width: 1024px) {
    font-size: 34px;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 25px;
      }
  }
  &__header {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &__text {
    width: 650px;
    @media (max-width: 1170px) {
      width: 100%;
    }
  }
  &__item {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      margin-top: 60px;
      flex-direction: column;
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;
      .cases__item__desc {
        margin-left: 0;
        margin-right: 60px;
        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
      @media (max-width: 768px) {
      flex-direction: column;
      }
    }
    &__img {
      width: 50%;
      height: 400px;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    &__desc {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }
      span {
        font-family: $font-OpenSans-Light;
        color: $gray-additional;
        font-size: 20px;
        line-height: 27px;
      }
      h3 {
        margin-top: 20px;
      }
      p {
        margin-top: 20px;
      }
      a {
        font-family: $font-OpenSans-Bold;
        color: $main-color;
        text-transform: uppercase;
        position: relative;
        padding-left: 15px;
        margin-top: 30px;
        &:after {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          left: 4px;
          top: 18px;
          border: 2px solid rgba(160, 119, 89, 1);
          border-radius: 100%;
          transition: .5s all;
          transform: translate(-50%, -50%);
          box-sizing: border-box;
          @media (max-width: 1024px) {
            top: 12px;
          }
        }
        &:hover {
          color: $main-color;
          &:after {
            width: 50px;
            height: 50px;
            border: 1px solid rgba(160, 119, 89, 0.5);
          }
        }
      }
    }
    &__img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

      }
    }

  }
  &__filter {
    margin-top: 30px;
          @media (max-width: 1024px) {
                margin-top: 65px;
          }

  }

}
.pagination-2 {
  justify-content: center;
  margin-top: 3px;
}