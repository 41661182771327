.footer {
  width: 100%;
  background-image: url(../../img/footer-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding-top: 90px;
  padding-bottom: 94px;
  margin-top: auto;
  position: relative;
  overflow: hidden;
  a {
    color: #fff;
  }
  @media(max-width: 1700px) {
    padding-top: 60px;
  }

  @media(max-width: 1023px) {
    padding-top: 27px;
  padding-bottom: 40px;

  }
  &__container {
    @include container();

    @media (max-width: 768px) {
      position: relative;
    }
  }
  &__logo {
        margin-top: 15px;
  @media(max-width: 1023px) {
        margin-bottom: 18px;
      }

  }
  &__line {
    width: 100%;
    height: 1px;
    background-color: $main-color;
    margin: auto 40px;
    @include sm(position, absolute);
    @include sm(left, 0);
    @include sm(right, 0);
    @include sm(width, auto);

  }
  &__top {
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 70px;
    @media(max-width: 1700px) {
      margin-bottom: 30px;
    }
    @media(max-width: 1023px) {
      display: block
    }
    @include md(margin-bottom, 47px);
    @include sm(position, unset);
  }
  &__socials {
    width: 100%;
    max-width: 355px;
    color: #fff;
    align-items: center;
    justify-content:  flex-end;
    @media(max-width: 768px) {
      padding-top: 34px;
    }
    @media(max-width: 600px) {
      justify-content: space-between !important;
      max-width: 100%
    }

    span {
      margin-right: 38px;
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;

        @media (max-width: 768px) {
              margin-right: 31px;
        }
      }
    ul {
      justify-content: flex-end;
      align-items: center;
      li {
        margin-right: 33px;
        &:last-child {
          margin-right: 0;
        }
          a {
            display: flex;
            align-items: center;
            svg {
              path {
                fill: $additional-color;
                &:hover {
                  fill: $secondary-color;
                }
              }
            }
          }
        @media (max-width: 768px) {
              margin-right: 21px;
            }
      }
    }
    &--sm {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
      @include sm(display, flex);
      span {
        margin-bottom: 15px;
      }
    }
  }
  &__bottom {
    margin: 0 -80px 54px 0;
    @media (max-width: 1700px) {
      margin-bottom: 15px;
      margin-right: -60px
    }
    @media (max-width: 1366px) {
      margin-right: -50px;
    }
    @media(max-width: $break_md) {
      margin-right: 0;
    }
    @include md(flex-direction, column);
    @include md(margin-bottom, 0);
    @include sm(padding-bottom, 0);
  }
  &__item {
    width: 25%;
    padding-right: 80px;
    &:nth-child(2) {
      width: 48%;
    }
    &:nth-child(3) {
      width: 27%;
    }

    @media (max-width: 1700px) {
      padding-right: 60px;
    }
    @media (max-width: 1366px) {
      padding-right: 50px;
    }

    @media(max-width: $break_md) {
      display: flex;
      width: 100%;
      padding-right: 0;
      flex-direction: column;
      margin-bottom: 23px;

    &:nth-child(2) {
      width: 100%;
    }
    &:nth-child(3) {
      width: 100%;
    }
    }
  }
  &-nav {
    @media(max-width: 1024px) {
      font-size: 14px
    }
    &__item {
      margin-bottom: 30px;
      transition: .5s all ease-in-out;
    @media(max-width: 768px) {
              margin-bottom: 18px;
            }
      &:before {
        content: "";
        position: absolute;
        top: 16px;
        left: 0;
        width: 0px;
        height: 2px;
        background-color: $main-color;
        transition: .5s all ease-in-out;
      }
      &:hover {
        position: relative;
        padding-left: 30px;
        transition: .5s all ease-in-out;
        &:before {
          width: 14px;
        }
      }
      &--active {
        position: relative;
        padding-left: 30px;
        &:before {
          content: "";
          position: absolute;
          top: 16px;
          left: 0;
          width: 14px;
          height: 2px;
          background-color: $main-color;
        }
        a {
          color: $white;
          font-weight: 700
        }
      }
    }
    a {
      font-family: $font-OpenSans-Bold
    }
    .submenu {
      margin-top: 15px;
      .footer-nav__item {
        margin-bottom: 17px
      }
      a {
        font-family: $font-OpenSans-Regular;
      }
    }
  &__link {
    cursor: pointer;
    &:hover {
      color: $white;
    }
    &--submenu {
      a {
        &:hover {
          color: #fff;
        }
      }
    }
  }

  }
  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    @include md(width, unset);
    span {
      margin-bottom: 5px;
    @media(max-width: 768px) {
      margin-bottom: 16px;
    }
      &:nth-child(3){
        margin-left: 30px;
      }
      img {
        margin-right: 17px;
        filter: brightness(0) invert(1);
      }
    }
    a {
      color: $white;
      text-transform: uppercase;
      text-align: right;
      &:hover {
        color: $main-color;
      }
    }
    &:empty {
      display: none;
    }
  }
  &__male {
    color: $main-color;
    img {
      margin-right: 17px;
    }
    a {
      color: inherit;
      &:hover {
        color: $secondary-color;
      }
    }
  }

  &__contact-title {
    color: $white;
    font-family: $font-CormorantGaramond-Bold;
    font-size: 28px;
    line-height:121.429%;
    letter-spacing: 0.8px;
    margin-bottom: 30px;
    @media(max-width: 1700px) {
      margin-bottom: 25px;
      font-size: 25px;
    }
    @media(max-width: 1366px) {
      margin-bottom: 22px;
      font-size: 22px;
    }
    @media(max-width: 768px) {
      margin-bottom: 22px;
      font-size: 30px;
    }
  }
  &__form {
    input {
      width: 100%;
      height: 60px;
      border: 1px solid $additional-color;
      background-color: rgba(0,0,0, .0);
      padding-left: 40px;
    }
    button {
      display: flex;
      margin-left: auto;
      align-items: center;
      justify-content: center;
    @include md(margin-left, 0);
    }
  }
  &__copyright {
    margin-bottom: 50px;
    @media(max-width: 1700px) {
      margin-bottom: 20px;
    }
    @include md(text-align, center);
    @include xs(font-size, 10px);
    a {
      &:hover {
        color: $main-color;
      }
    }
  }
  &__contact-us {
    @media(max-width:768px) {
  margin-bottom: 18px
      }
  }
}
.footer-bottom-nav {
  margin: 40px -40px 0;
  display: flex;
  flex-wrap: wrap;
    @media(max-width:1023px) {
      margin-top: 20px
    }
  &__item {
    padding: 0 40px;
    width: 33.333%;

    @media(max-width: 767px) {
      width: 100%;
      margin-bottom: 44px;
    }
  }
  &__title {
    padding-bottom: 26px;
    border-bottom: 2px solid $main-color;
    margin-bottom: 24px;
    @media(max-width:1023px) {
      font-size: 14px;
      padding-bottom: 21px;
    margin-bottom: 16px;
    }
    a {
      color: rgba(255, 255, 255, 0.40);
      text-transform: uppercase;
      font-weight: 700
    }
  }
  .submenu {
      line-height: 170%;
    list-style: none;
    li + li {
      margin-top: 17px
    }
    a {
      color: #FFF;
      &:hover {
        color: $main-color;
      }
    }
  }
}