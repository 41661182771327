.news-page {

  &__container {
    @include container();
    @media (max-width: 1700px) {
      padding-right: 80px;
    }
    @media (max-width: 1024px) {
      padding-right: 20px;
    }

  }
  &__content {
    padding-top: 60px;
    padding-bottom: 88px;
    width: 1100px;
    margin-left: 200px;
    @media (max-width: 1612px) {
      margin:  0 auto;
    }
    @media (max-width: 1280px) {
      width: 100%;
    }
    @media (max-width: 1024px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    span {
      display: block;
      color: $gray-color;
      margin-bottom: 30px;
      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 54px;
      line-height: 65px;
      font-family: $font-CormorantGaramond-Bold;
      letter-spacing: .8px;
      @media (max-width: 1700px) {
        font-size: 48px;
        line-height: 58px;
      }
      @media (max-width: 1024px) {
        margin-bottom: -20px;
        font-size: 26px;
        line-height: 34px;

      }
    }
    p {
      margin-top: 40px;
    }
    img  {
      margin-top: 40px;
      width: 100%;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 150px;
      }
    }
    a {
      color: $secondary-color;
    }
    blockquote {
      margin-top: 30px;
      margin-bottom: 30px;
      b {
        @media (max-width: 1024px) {
          display: block;
          margin-bottom: 30px;
        }
      }
      ul {
        padding-left: 40px;
        li {
          position: relative;
          @media (max-width: 1024px) {
            margin-bottom: 20px;
          }
          &:before {
            content: "";
            background: url("../../img/icons/elipse-3.svg");
            width: 12px;
            height: 12px;
            position: absolute;
            top: 12px;
            left: -25px;
          }
        }
      }
    }
  }
  &__footer {
    padding-bottom: 160px;
    justify-content: space-between;
    @media (max-width: 1024px) {
      padding-bottom: 58px;
    }
      @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__socials {
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    li {
      margin-right: 40px;
    }
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: space-around;
    }
  }
  &__prev {
    margin-right: 100px;
    align-items: center;
    @media (max-width: 1200px) {
      margin-right: 60px;
    }

    span {
      margin-left: 25px;
      color: $main-color;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  &__next {
    align-items: center;
    span {
      margin-right: 25px;
      color: $main-color;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  &__prev a, &__next a {
    width: 40px;
    height: 40px;
    border: 1px solid $main-color;

  }
  &__prev a {
    background: url("../../img/icons/next-arrow.svg"), $white;
    transform: rotate(180deg);
    background-repeat: no-repeat!important;
    background-position: center!important;
    &:hover {
      background: url("../../img/icons/arrow-white.svg"), $main-color;
      transform: rotate(0deg);
    }
  }
  &__next a {
    background: url("../../img/icons/next-arrow.svg"), $white;
    background-repeat: no-repeat!important;
    background-position: center!important;
    &:hover {
      background: url("../../img/icons/arrow-white.svg"), $main-color;
      transform: rotate(180deg);
    }
  }
}
.news__title--page {
  font-weight: 700;
  font-size: 54px;
  line-height: 65px;
  font-family: CormorantGaramond-Bold;
  letter-spacing: .8px;
  @media (max-width: 1700px) {
    font-size: 48px;
    line-height: 58px;
  }
  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 34px;
  }
}
.news-page {
      .container-full {
        padding-top: 6px;
        }
}