@font-face {
  font-family: "CormorantGaramond-Bold";
  src: url("/fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/CormorantGaramond-Bol/CormorantGaramond-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.woff") format("woff"), /* Modern Browsers */
  url("/fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant-Regular";
  src: url("/fonts/Cormorant-Regular/Cormorant-Regular.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Cormorant-Regular/Cormorant-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Cormorant-Regular/Cormorant-Regular.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Cormorant-Regular/Cormorant-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant-Bold";
  src: url("/fonts/Cormorant-Bold/Cormorant-Bold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Cormorant-Bold/Cormorant-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Cormorant-Bold/Cormorant-Bold.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Cormorant-Bold/Cormorant-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("/fonts/OpenSans-Bold/OpenSans-Bold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/OpenSans-Bold/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/OpenSans-Bold/OpenSans-Bold.woff") format("woff"), /* Modern Browsers */
  url("/fonts/OpenSans-Bold/OpenSans-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("/fonts/OpenSans-Regular/OpenSans-Regular.eot"); /* IE9 Compat Modes */
  src: url("/fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/OpenSans-Regular/OpenSans-Regular.woff") format("woff"), /* Modern Browsers */
  url("/fonts/OpenSans-Regular/OpenSans-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("/fonts/OpenSans-Light/OpenSans-Light.eot"); /* IE9 Compat Modes */
  src: url("/fonts/OpenSans-Light/OpenSans-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/OpenSans-Light/OpenSans-Light.woff") format("woff"), /* Modern Browsers */
  url("/fonts/OpenSans-Light/OpenSans-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans-Semibold";
  src: url("/fonts/OpenSans-Semibold/OpenSans-Semibold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/OpenSans-Semibold/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/OpenSans-Semibold/OpenSans-Semibold.woff") format("woff"), /* Modern Browsers */
  url("/fonts/OpenSans-Semibold/OpenSans-Semibold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Semibold";
  src: url("/fonts/OpenSans-SemiBoldItalic/OpenSans-SemiBoldItalic.eot"); /* IE9 Compat Modes */
  src: url("/fonts/OpenSans-SemiBoldItalic/OpenSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/OpenSans-SemiBoldItalic/OpenSans-SemiBoldItalic.woff") format("woff"), /* Modern Browsers */
  url("/fonts/OpenSans-SemiBoldItalic/OpenSans-SemiBoldItalic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: italic;
}
