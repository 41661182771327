.left-panel{
  width: 154px;
  height: 100vh;
  padding-top: 58px;
  padding-bottom: 58px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid $main-color;
  position: fixed;
  top: 0;
  z-index: 101;
  background-color: $white;
  left: -170px;
  transition: 1s all ease-in-out;
    @media (min-width: 1025px) {
      display: none;
    }
  @media (max-width: 1700px) {
    width: 120px;
    padding-top: 38px;
    padding-bottom: 38px;
  }
  &--active {
    left: 0;
  }
  .icon-menu {
    display: flex;
    width: 60px;
    height: 28px;
    &__burger {
      position: relative;
      width: 60px;
      height: 17px;
      margin: 0 auto 2px;
      cursor: pointer;
      transition: .5s ease-in-out;
      transform: rotate(90deg);
      span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 4px;
        transition: .25s ease-in-out;
        transform: rotate(0deg);
        opacity: 1;
        border-radius: 0px;
        background: $black-color;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 10px;
        }
        &:nth-child(4) {
          top: 20px;
        }
      }
    }
  }
  &__socials {
    ul {
      flex-direction: column-reverse;
      align-items: center;
      li {
        margin-bottom: 24px;
        a {
          svg {
            path {
              fill: #D9BEA4;
              &:hover {
                fill: $main-color;
              }
            }
          }
        }
      }
    }
  }
  .lang {
    &__item {
      a {
        text-transform: uppercase;
        color: $main-color;
        cursor: pointer;
        line-height: 20px;
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
        }
        &:hover {
          color: $black-color;
          font-weight: 700;
        }
      }
      &--active {
        a {
          color: $black-color;
          font-weight: 700;
        }
      }
    }
  }
}
