.service {
  &__container {
    @include container();
    display: flex;
    position: relative;
    margin-bottom: 79px;
    @media (max-width: 1700px) {
      margin-bottom: 80px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &__sidebar {
    padding-top: 84px;
    padding-right: 70px;
    margin-right: 6.5%;
    margin-top: -3px;
    z-index: 20;
    width: 405px;
    @media (max-width: 1700px) {
      padding-right: 50px;
      padding-top: 70px;
      width: 350px;
    }
    @media (max-width: 1280px) {
      padding-right: 30px;
      margin-right: 40px;
      margin-right: 0;
      padding-top: 30px;
    }
    @media (max-width: 1024px) {
     // height: 100%;
      position: relative;
      width: 100%;
    padding-right: 0;
    margin-right: 0;
            padding-top: 45px;
    }
    h3 {
      position: relative;
      padding-bottom: 20px;
      &:after {
        content: "";
        height: 2px;
        background: $main-color;
        position: absolute;
        left: -1000088px;
        right: 0;
        bottom: 0;

      }
    @media (max-width: 1024px) {
      font-size: 30px
    }
    }
    &-wrap {
      position: sticky;
      top: 120px;
    @media (max-width: 1024px) {
      position: static;
      padding-top: 30px
    }
    &.full {
      .service__item {
        display: block;
      }
    }
    }
    &--fixed {
      position: fixed;
      margin-top: 55px;
      height: 93vh;
      @media (max-width: 1024px) {
        width: 100%;
        height: 130px;
        padding: 15px 15px 0;
      }
    }
    &--mdHeight {
      height: 100vh;
      transition: .5s all ease-in-out;
    }
  }

  &__items {
    width: 366px;
    margin-top: 79px;
   // height: 100%;
    padding-right: 10px;
    @media (max-width: 1700px) {
      //width: 294px;
    }
    @media (max-width: 1280px) {
      //width: 260px;
     // height: 83%;
    }
    @media (max-width: 1024px) {
      width: 100%;
      //display: none;
      transition: .5s all ease-in-out;
      margin-top: 0;
     // height: 60vh;
    }
    &::-webkit-scrollbar {
      width: 7px;
      @media (max-width: 1024px) {
        width: 10px;
      }
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 255.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $main-color;
    }
  }
  &__item {
    margin-bottom: 37px;
    position: relative;
    transition: .5s all ease-in-out;
      padding-left: 20px;
      display: none;

    @media (max-width: 1024px) {
      margin-bottom: 17px
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6),&:nth-child(7),&:nth-child(8) {
      display: block;
    }
    &:before {
      content: "";
      position: absolute;
      top: 11px;
      left: 0;
      bottom: 0;
      background-image: url("../../img/icons/Ellipse-main-color.svg");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      transition: .5s all ease-in-out;
      opacity: 1;
      @media (max-width: 1280px) {
        top: 8px;
        //left: -17px;
      }
    }
    &:hover {
      font-family: $font-OpenSans-Bold;

      &:before {
        opacity: 1;
      }
    }
    a {
      color: $black-color;
      position: relative;
      &:hover {
        font-family: $font-OpenSans-Bold;
        color: $main-color;
      }
    }
    &--active {
      font-family: $font-OpenSans-Bold;
      a {
        font-family: $font-OpenSans-Bold;
        color: $main-color;
      }
    }
  }
  &__content {
    margin-top: 82px;

    @media (max-width: 1366px) {
      margin-top: 83px;
    }
    @media (max-width: 1280px) {
      margin-left: 36px;
    }
    @media (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
    }
    &--mdFixed {
      margin-top: 300px;
    }
      p {
        margin-top: 25px;
    @media (max-width: 1024px) {
      margin: 13px 0;
    }
      }

    &--fixed {
      margin-left: 540px;
      @media (max-width: 1700px) {
        margin-left: 460px;
      }
      @media (max-width: 1280px) {
        margin-left: 360px;
      }
      @media (max-width: 1024px) {
        margin-left: 0;
      }
    }
  }
  &__select {
    display: none;
    @media (max-width: 1024px) {
      //display: block;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &__info {
    position: relative;
    display: block;
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      top: 42%;
      right: 0;
      background: url("../../img/icons/services_arrpow.svg") no-repeat;
      height: 10px;
      width: 14px;
      transition: .5s all ease-in-out;
    }
    &--active {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
  .consultation {
    padding-bottom: 0;
  }
  &__first-page {
    margin-top: 80px;
    @media (max-width: 1280px) {
      margin-left: 40px;
    }

    @media (max-width: 768px) {
      margin-top: 40px;
      margin-left: 25px;
      padding-right: 10px;
    }

/*    ul {
      padding-left: 40px;
      li {
        position: relative;
        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }
        &:before {
          content: "";
          background: url("../../img/icons/elipse-3.svg");
          width: 12px;
          height: 12px;
          position: absolute;
          top: 12px;
          left: -25px;
        }
      }
    }*/
  }
}
.service__first-page, .service__content {
    h1 {
      Font-family: $font-CormorantGaramond-Bold;
      letter-spacing: .8px;
      font-size: 54px;
      line-height: 121.429%;
          margin-bottom: 62px;
      @media (max-width: 1700px) {
        font-size: 48px;
      }
      @media (max-width: 1280px) {
        font-size: 34px;
      }
      @media (max-width: 768px) {
        margin-bottom: 28px;
      }
    }
    h2 {
      margin-top: 64px;
      font-family: $font-CormorantGaramond-Bold;
      letter-spacing: .8px;
      font-size: 28px;
      line-height: 121.429%;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    a {
      text-decoration: underline !important;
      font-family: $font-OpenSans-Semibold !important;
      color: $main-color !important;
      &:hover {
        color: $main-color !important;
        text-decoration: none !important;
      }
       * {
        font-family: inherit !important;
        color: inherit !important;
        text-decoration: none !important;
       }
    }
      b {
        @media (max-width: 1280px) {
          font-size: 14px;
          line-height: 24px;
        }
        @media (max-width: 1024px) {
          display: block;
          margin-bottom: 30px;
        }
      }
      ul {
        padding-left: 0;
        margin-left: 17px;
        list-style: disc;
        margin-top: 15px;
        li {
          position: relative;
          list-style: disc;
          @media (max-width: 1024px) {
            margin-bottom: 20px;
          }
          &:before {
            display: none;
            content: "";
            background: url("../../img/icons/elipse-3.svg");
            width: 12px;
            height: 12px;
            position: absolute;
            top: 12px;
            left: -25px;
          }
        }
        li + li {
          margin-top: 3px
        }
      }
    blockquote {
      margin-top: 30px;
      margin-bottom: 30px;
    }
}
.container-full__wrapper .breadcrumb__container {
    margin-left: 0;
}
.container-full_service {
  flex-direction: column;
}
.section-checklist {
  background: #000 url(/img/quote-advantages.jpg) 0 0 no-repeat;
  background-size: cover;
  padding: 118px 0 120px;
  margin: 50px 0 0;
  color: #fff;
  position: relative;
  @media (max-width: 1700px) {
      padding: 87px 0 80px;
  }
  @media (max-width: 1366px) {
     padding: 40px 0 64px;
   }
  @media (max-width: 1023px) {
     &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 20px ;
      height: 1px;
      background: #A07759;
      right: 20px;
     }
  }
  & +.consultation {
  @media (max-width: 1024px) {
        padding-top: 60px;
      }
    }
}
.checklist {
  margin-top: 80px;
  display: flex;
  align-items: center;
  @media (max-width: 1700px) {
    margin-top: 56px;
  }
        @media (max-width: 1023px) {
  display: block;
          margin-top: 30px;
        }
  &__description {
    margin-right: 5%;
        @media (max-width: 1023px) {
          margin-right: 0%;
        }
    p {
      margin: 15px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__btn {
    flex-shrink: 0;
        @media (max-width: 1023px) {
          margin-top: 23px;
        }
    .btn {
      display: inline-flex;
      height: 60px;
      min-width: 260px;
      padding: 0 24px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
.faq {
  margin-top: 39px;
  &__body {
    display: none;
  }
  &__title {
    font-family: $font-CormorantGaramond-Bold;
    font-size: 28px;
    line-height: 121.429%;
    letter-spacing: 0.8px;
    cursor: pointer;
        @media (max-width: 1024px) {
          font-size: 24px;
        }
    &:before  {
      content: '➤';
      font-size: 28px;
      color: $main-color;
      margin-right: 8px;
      flex-shrink: 0;
      transition:  transform .8s ease;
        @media (max-width: 1024px) {
      font-size: 24px;
    }
    }
    &:hover {
      &:before {
        transform: translateX(-10px);
      }
    }
  }
}
.cost-price {
  margin-top: 15px;
  font-size: 14px;
font-style: italic;
font-family: $font-OpenSans-Semibold;
letter-spacing: 0.32px;
background: $additional-color;
padding: 3px 20px 4px;
}
.more-link {
letter-spacing: 0.32px;
text-transform: uppercase;
color: $main-color;
font-family:  $font-OpenSans-Bold;
display: inline-flex;
align-items: center;
cursor: pointer;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
  i {
    height: 8px;
    width: 8px;
    margin-top: -7px;
    border: 1px solid $black-color;
    border-left-width: 0;
    border-top-width: 0;
    display: block;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    margin-left: 29px;
      transform: rotate(45deg);
      transform-origin: 0 0
  }
  &:hover {
    color: $secondary-color
  }
}
.full {
  .more-link i {
    transform: rotate(-135deg);
    margin-top: 23px;
  }
}
.more-btn-wrap {
  margin-top: 43px;
          @media (max-width: 1024px) {
                margin-top: 30px;
              }
}
.container-full__wrapper--service   {

          @media (max-width: 768px) {
      padding-bottom: 45px !important;
      padding-top: 33px !important;
      padding-bottom: 71px !important;
    }
  .title {
    @media (max-width: 768px) {
      font-size: 40px;
      margin-bottom: 11px;
      }
    }

    @media (max-width: 768px) {
      .breadcrumb {
        padding-top: 0
      }
    }
  .container-full__desc {
    @media (max-width: 768px) {
     color: #fff;
     }
   }
   }
