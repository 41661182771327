.main-screen {
  margin-bottom: 99px;
  position: relative;
  min-height: 400px;
  overflow: hidden;

  @media (max-width: 1500px) {
    min-height: 0;
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    padding-left: 0px;
    margin-top: 55px;
    margin-left: 0 !important;
    margin-bottom: 40px !important
  }

  @include md(margin-left, 20px);
  @include md(margin-bottom, 80px);

  &__desc {
    margin-top: 100px;
    position: relative;
    width: 55%;
    bottom: -300px;
    transition: 1.5s all ease-in-out;
    z-index: 10;

    @media (max-width: 1700px) {
      margin-top: 80px;
    }

    @media (max-width: 1366px) {
      padding-left: 20px;
      width: 80%
    }

    @media (max-width: 1280px) {
      position: unset;
      padding-left: 0;
      margin: unset;
    }

    @media (max-width: 1024px) {
      margin-top: 50px !important;
    }

    @media (max-width: 768px) {
      width: 100%
    }

    @include md(margin-top, 40px);

    &--anim {
      bottom: 0;
    }

    span {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 24px;
      display: block;
      color: $gray-color;
      font-family: $font-OpenSans-Regular;

      @media (max-width: 1024px) {
        margin-bottom: 27px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    h3 {
      margin-bottom: 56px;
      width: 590px;

      @media (max-width: 1700px) {
        font-size: 52px;
        width: 480px;
        margin-bottom: 40px;
      }

      @media (max-width: 1280px) {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 34px;
        width: 100%;
      }
    }
  }

  h1 {
    @media (max-width: 1024px) {
      letter-spacing: 0.8px;
      font-size: 40px;
      line-height: 85%;
    }
  }

  &__btn {
    margin-top: 20px;

    @media (max-width: 1280px) {
      justify-content: center;
      align-items: center;
      display: none;
    }

    @media (max-width: 1024px) {
      margin-top: 24px;
      height: 60px;
    }

    &--mob {
      display: none;

      @media (max-width: 1280px) {
        display: block;
      }
    }
  }

  &__text {
    margin-top: 43px;

    @media (max-width: 1024px) {
      margin-top: 23px;
    }

    p {
      margin-bottom: 30px;

      @media (max-width: 1280px) {
        margin-bottom: 20px;
      }
    }
  }
}

.main-page {
  overflow: hidden;
}

.h1 {
  font-family: $font-CormorantGaramond-Bold;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: 98.864%;
  letter-spacing: 2.5px;
  -moz-font-weight: normal;
  -webkit-font-weight: normal;

  @media(max-width: 1700px) {
    font-size: 62px;
    line-height: 63px;
  }

  @media(max-width: 1024px) {
    font-size: 28px;
    line-height: 34px;
  }
}

.main-slider {
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  margin-left: -120px;

  &__text {
    opacity: 0;

    &--active {
      opacity: 1;
    }
  }
}

.container {
  @include container();
}

.btn-2 {
  font-family: $font-OpenSans-Bold;
  text-transform: uppercase;
  color: $main-color;
  position: relative;
  padding-left: 20px;
  margin-top: auto;
  cursor: pointer;

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 4px;
    top: 16px;
    border: 2px solid rgba(160, 119, 89, 1);
    border-radius: 100%;
    transition: .5s all;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    @media (max-width: 1024px) {
      top: 12px;
    }
  }

  &:hover {
    color: $main-color;

    &:after {
      width: 50px;
      height: 50px;
      border: 1px solid rgba(160, 119, 89, 0.5);
    }
  }

  @media (max-width: 768px) {
    font-size: 14px;

    &:after {
      top: 14px;
    }
  }
}

.btn_1 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.main-services {
  &__container {
    @include container();

    &:before {
      content: '';
      display: block;
      height: 2px;
      background: $main-color;
      margin-bottom: 20px;

      @media (max-width: 1024px) {
        margin-bottom: 24px;
      }
    }
  }

  &__items {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 510px) {
      margin: 0
    }
  }

  &__item {
    background-color: $main-color;
    width: calc(33.333% - 40px);
    margin: 20px 20px;
    padding: 60px;
    position: relative;
    color: #fff;

    &:hover .main-services__bg {
      opacity: 0;
    }

    @media (max-width: 1700px) {
      padding: 50px;
    }

    @media (max-width: 1366px) {
      padding: 40px;
    }

    @media (max-width: 1024px) {
      width: calc(50% - 40px);
      padding: 26px;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 25px
    }
  }

  &__bg {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    left: 0;
    opacity: 1;
    height: 1px;
    bottom: 0;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 0;
    transition: opacity .8s ease;

    &:before {
      content: '';
      background: rgba(#2F2F2F, .8);
      width: 100%;
      display: block;
      height: 100%;
    }

  }

  &__title {
    font-size: 28px;
    font-family: $font-CormorantGaramond-Bold;
    line-height: 121.429%;
    letter-spacing: 0.8px;
    min-height: 80px;
    position: relative;
    z-index: 2;

    @media (max-width: 767px) {
      font-size: 34px;
      min-height: 0;
      letter-spacing: 0;
    }
  }

  &__text {
    margin-top: 14px;
    padding-bottom: 22px;
    position: relative;
    z-index: 2;

    &:after {
      content: '';
      display: block;
      left: 0;
      height: 1px;
      background: $gray-color;
      bottom: 0;
      width: 100%;
      position: absolute;
    }

    @media (max-width: 767px) {
      margin-top: 25px;
      padding-bottom: 20px;
    }
  }

  &__bg-decor {

    background-image: url(../../img/decor-services.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    left: 0;
    height: 1px;
    bottom: 0;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 1
  }
}

.about {
  margin-top: 180px;
  overflow: hidden;
  @include md(padding-bottom, 60px);

  @media (max-width: 1700px) {
    margin-top: 110px
  }

  @media (max-width: 767px) {
    margin-top: 53px;
    padding-bottom: 33px;

  }

  &__container {
    @include container();
  }

  &__title {
    width: 800px;
    position: relative;
    margin-bottom: 80px;
    @include md(width, 100%);
    @include md(margin-bottom, 26px);
    @include md(padding-left, 0);

    &:after {
      content: "";
      position: absolute;
      top: 75px;
      left: 820px;
      width: 0%;
      height: 1px;
      background-color: $main-color;
      transition: 1s all ease-in-out;

      @media (max-width: 1024px) {
        top: unset;
        bottom: 8px;
        left: unset;
        margin-left: 20px;
      }

      @media (max-width: 1024px) {
        display: none
      }
    }

    b {
      font-size: 86px;
      line-height: 104px;

      @media (max-width: 1700px) {
        font-size: 78px;
        line-height: 94px;
      }

      @include md(font-size, 34px);
      @include md(line-height, 41px);

    }
  }

  &__item {
    align-items: center;
    @include md(flex-direction, column);

    @media (max-width: 1280px) {
      flex-direction: column;
    }
  }

  &__text {
    margin-right: 90px;
    @include md(margin-right, 0);
    @include md(margin-bottom, 8px);
    @include md(padding-left, 0);

    p {
      margin-bottom: 25px;

      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }
    ul {
      list-style: disc inside;
      margin-bottom: 25px;

      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
      li {
        list-style: inherit;
      }
    }
  }

  &__img {
    max-width: 860px;
    height: 410px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1700px) {
      margin-right: 0;
    }

    @media (max-width: 1280px) {
      margin-right: 0;
      width: 100%;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      width: 100%;
      height: unset;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      position: relative;
      right: -600px;
      transition: 1s all ease-in-out;

      @media (max-width: 1700px) {
        width: 600px;
        height: 340px;
      }

      @media (max-width: 1280px) {
        width: 100%;
        height: 100%;
        right: 0;
      }

      @media (max-width: 1024px) {
        right: 0;
      }
    }

    &--animate {
      img {
        right: 0;
      }
    }
  }
}

.advantages {
  overflow: hidden;
  padding-top: 105px;
  background-image: url(../../img/advantages-bg-img.svg);
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  &__container {
    @include container();
    background-image: url("../../img/advantages-bg-img.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    width: 100%;
    padding-bottom: 102px;
    padding-top: 90px;

    @media (max-width: 1700px) {
      padding-bottom: 80px;
    }

    @media (max-width: 1400px) {
      flex-direction: column;
    }

    @include md(flex-direction, column);
    @include md(padding-bottom, 60px);
    @include md(padding-top, 59px);
  }

  &__desc {
    position: absolute;
    top: 107px;
    left: 150px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1700px) {
      top: 77px;
      left: 100px;
      height: 320px;
    }

    @media (max-width: 1400px) {
      left: 50%;
      transform: translateX(-50%);
    }

    @include md(top, 75px);
    @include xs(top, 40px);

    b {
      font-size: 144px;
      line-height: 124px;
      color: $main-color;
      font-family: $font-Cormorant-Regular;
      font-weight: 400;
      text-align: center;

      @media (max-width: 1700px) {
        font-size: 100px;
        line-height: 90px;
      }

      @include xs(font-size, 72px);
      @include xs(line-height, 70px);

    }

    span {
      line-height: 25px;
      width: 120px;
      text-align: center;
      @include xs(font-size, 12px);
      @include xs(line-height, 16px);
    }
  }

  &__items {
    margin: 0 -35px;
    width: calc(100% + 70px);
    display: flex;
    flex-wrap: wrap;
    color: $additional-color;
    justify-content: space-between;

    @media (max-width: 1600px) {
      margin: 0 -25px;
      width: calc(100% + 50px);
    }

    @include md(flex-direction, column);
    @include md(flex-direction, unset);
    @include md(justify-content, center);

    @media (max-width: 539px) {
      justify-content: flex-start;
    }
  }

  &__item {
    width: 25%;
    padding: 30px 35px;

    @media (max-width: 1600px) {
      padding: 20px 25px 22px;
    }

    @media (max-width: 1023px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;

      &:first-child {
        .advantages__inner:before {
          display: none;
        }
      }
    }


    p {
      height: auto;
      position: relative;
      transition: 1s all ease-in-out;
      width: 100%
    }
  }

  &__inner {
    position: relative;
    font-size: 88px;
    line-height: 98.864%;
    letter-spacing: 2.5px;
    font-family: $font-Cormorant-Bold;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: $additional-color;
      margin: 0 0 5px;

      @media (max-width: 767px) {
        margin: 0 0 35px;
      }
    }

    @media (max-width: 1700px) {
      font-size: 78px;
    }

    @media (max-width: 1366px) {
      font-size: 68px;
    }

    @media (max-width: 1280px) {
      font-size: 55px;
    }

    @media (max-width: 767px) {
      font-size: 40px;
    }
  }

  &__text {
    margin-top: 30px;
    clip-path: polygon(0% 0, 100% 0, 100% 20%, 100% 80%, 100% 98%, 0% 100%, 0% 81%, 0% 20%);
    margin-left: -20px;
    line-height: 1.4;

    @media (max-width: 1024px) {
      clip-path: unset;
      margin-left: unset;
    }

    @media (max-width: 767px) {
      margin-top: 15px;
    }
  }

  &__quote {
    @include container();
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 120px;
    @include md(margin-bottom, 10px);
    @include md(margin-top, 41px);

    .animate-line {
      overflow: hidden;
    }

    b {
      font-size: 72px;
      line-height: 87px;
      font-family: $font-CormorantGaramond-Bold;
      padding-left: 110px;
      letter-spacing: 2.5px;
      position: relative;
      margin-bottom: 80px;

      @media (max-width: 1700px) {
        font-size: 54px;
        line-height: 65px;
      }

      @include md(font-size, 34px);
      @include md(line-height, 120%);
      @include md(padding-left, 43px);
      @include md(margin-bottom, 20px);
      @include md(letter-spacing, 0);

      &:before {
        content: "\201c";
        font-size: 144px;
        line-height: 174px;
        color: $main-color;
        position: absolute;
        top: -40px;
        left: 20px;

        @media (max-width: 1700px) {
          font-size: 100px;
          line-height: 120px;
        }

        @include md(font-size, 72px);
        @include md(line-height, 87px);
        @include md(top, -9px);
        @include md(left, -3px);
      }
    }

    span {
      position: relative;
      text-align: right;
      font-size: 24px;
      line-height: 32px;
      color: $gray-additional;

      @media (max-width: 1700px) {
        font-size: 22px;
      }

      @media (max-width: 1024px) {
        font-size: 16px;
      }

      @include sm(font-size, 12px);

      &:after {
        content: "";
        position: absolute;
        top: 15px;
        left: -166px;
        width: 0%;
        height: 1px;
        background-color: $main-color;
        @include sm(left, -90px);

        @media (max-width: 1024px) {
          left: -146px;
        }

        @media (max-width: 1024px) {
          left: -87px;
        }
      }
    }
  }
}

.services {
  background: url(../../img/services-bg.svg), $main-color;
  background-repeat: no-repeat;
  margin-top: 306px;

  @media (max-width: 1170px) {
    margin-top: 205px;
  }

  @media (max-width: 768px) {
    margin-top: 614px;
  }

  &__container {
    @include container();
    justify-content: space-between;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__items {
    padding: 149px 5% 151px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //height: 737px;
    width: calc(100% - 405px);
    padding-right: 9%;

    @media (max-width: 1700px) {
      padding: 100px 5% 100px 0;
      width: calc(100% - 350px);
    }

    @media (max-width: 768px) {
      margin-top: 39px;
      padding: 39px 0 40px 0;
      width: 100%;
    }
  }

  &__item {
    margin-bottom: 80px;
    font-size: 54px;
    line-height: 1.21;
    font-family: $font-CormorantGaramond-Bold;
    color: #725139;
    cursor: pointer;
    position: relative;
    padding-left: 33px;
    max-width: 1010px;
    transition: opacity 1s ease;

    &:last-child {
      margin-bottom: 0;
      flex-wrap: wrap;
    }

    @media (max-width: 1700px) {
      font-size: 42px;
      margin-bottom: 60px;
    }

    @media (max-width: 1440px) {
      font-size: 32px;
      margin-bottom: 55px;
    }

    @media (max-width: 1170px) {
      font-size: 30px;
    }

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 31px;
      margin-bottom: 43px;
      padding-left: 26px;
    }

    a {
      color: inherit;
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

    }

    &:before {
      content: "";
      background-image: url("../../img/icons/Ellipse.svg");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 4px;
      top: 25px;
      z-index: 2;

      @media (max-width: 1700px) {
        top: 18px;
      }

      @media (max-width: 1440px) {
        top: 12px;
      }

      @media (max-width: 768px) {
        top: 9px;
      }
    }

    &:after {
      content: "";
      width: 13px;
      height: 13px;
      position: absolute;
      left: 12px;
      top: 33px;
      border: 1px solid rgba(255, 255, 255, .4);
      border-radius: 100%;
      transition: .5s all;
      transform: translate(-50%, -50%);
      box-sizing: border-box;

      @media (max-width: 1700px) {
        top: 26px;
      }

      @media (max-width: 1440px) {
        top: 20px;
      }

      @media (max-width: 768px) {
        top: 16px;
      }
    }

    &:hover {
      color: $white;

      &:after {
        width: 124px;
        height: 124px;

        @media (max-width: 768px) {
          width: 70px;
          height: 70px;
        }
      }

      .services__line {
        background: #fff;
      }
    }

    &--active {
      color: #fff;

      &:after {
        content: "";
        width: 124px;
        height: 124px;

        @media (max-width: 768px) {
          width: 43px;
          height: 43px;
        }
      }

      .services__line {
        background: #fff;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 405px;

    @media (max-width: 1700px) {
      width: 350px;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    @include md(position, unset);

    @media (max-width: 768px) {
      margin-top: 39px;
    }
  }

  &__desc {
    width: 412px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;

    @media (max-width: 1700px) {
      width: 350px;
    }

    @media (max-width: 768px) {
      height: auto;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 440px;
      width: 0%;
      height: 2px;
      background: #fff;

      @media (max-width: 1700px) {
        top: 310px;
      }
    }
  }

  &__title {
    margin-bottom: 36px;
    font-size: 28px;
    font-family: $font-CormorantGaramond-Bold;
    font-weight: 700;
    line-height: 121.429%;
    letter-spacing: 0.8px;

    @media (max-width: 1700px) {
      font-size: 25px;
      margin-bottom: 30px
    }

    @media (max-width: 1366px) {
      font-size: 23px;
      margin-bottom: 25px
    }

    @media (max-width: 768px) {
      font-size: 34px;
      margin-bottom: 18px;
    }
  }

  &__text {
    border-top: 2px solid #fff;
    color: $white;
    position: absolute;
    left: 0;
    top: 520px;
    padding-top: 40px;
    opacity: 0;

    @media (max-width: 1700px) {
      top: 375px;
      padding-top: 35px
    }

    @media (max-width: 1366px) {
      top: 370px;
      padding-top: 25px
    }

    @media (max-width: 768px) {
      pointer-events: none;
      padding-top: 15px;

      &.services__text--active {
        position: static;
        pointer-events: auto;
      }
    }

    &--active {
      opacity: 1;
      animation: textUp 1.5s 1;

    }

    @keyframes textUp {
      0% {
        opacity: 0;
      }

      100% {}
    }

    @keyframes textUp-lg {
      0% {
        line-height: 36px;
        top: 370px;
        opacity: 0;
      }

      100% {
        line-height: 28px;
        top: 350px;
        opacity: 1;
      }
    }
  }

  .slide-services {
    overflow: hidden;
    width: 412px;
    height: 600px;
    position: absolute;
    top: -119px;
    clip: rect(0, 412px, 600px, 0px);

    img {
      height: 600px
    }

    @media (max-width: 1700px) {
      width: 350px;
      height: 450px;

      img {
        height: 450px
      }
    }

    @media (max-width: 768px) {
      width: 100vw;
      height: 542px;
      clip: unset;
      top: -542px;
      max-width: 480px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__item {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    transition: opacity 1s ease;

      &--hidden {
        bottom: -600px;
        transition: 1s all ease-in-out;

        @media (max-width: 768px) {
          bottom: unset;
          transition: unset;
        }
      }

      &--show {
        bottom: 0;
      }

      &--a {
        z-index: 3
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  #slider2 {
    opacity: 0;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }

  #slider3 {
    opacity: 0;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }

  #slider4 {
    opacity: 0;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }

  #slider5 {
    opacity: 0;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }
  .prev {
      z-index: 6
  }  
  .prev {
    z-index: 2;
    opacity: 0;
    transition: opacity 0s 1s
  }

  .slide-services__item--a {
    z-index: 7 !important;
    opacity: 1 !important;
  }

  @keyframes opacity-slide {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slide {
    100% {
      transform: translateY(0%);
    }

    0% {
      transform: translateY(100%);
    }
  }

  @keyframes hidden {
    100% {
      z-index: 2;
    }

    0% {
      z-index: 2;
    }
  }

  &__value {
    font-size: 28px;
    font-family: $font-CormorantGaramond-Bold;
    line-height: 121.429%;
    letter-spacing: 0.8px;

    @media (max-width: 1440px) {
      font-size: 22px;
    }

    @media (max-width: 1170px) {
      font-size: 18px;
    }

    @media (max-width: 768px) {
      width: 100%;
      font-family: $font-OpenSans-Semibold;
      margin-top: 18px;
    }
  }

  &__line {
    background: #725139;
    height: 1px;
    width: 22%;
    margin: 0 9% 0 auto;
    display: block;
  }
}

.projects {
  overflow: hidden;
  padding-top: 205px;
  padding-bottom: 140px;
  @include md(padding-top, 80px);
  @include md(padding-bottom, 80px);

  @media (max-width: 1700px) {
    padding-top: 125px;
  }

  @media (max-width: 1366px) {
    padding-top: 85px;
  }

  @media (max-width: 768px) {
    padding-top: 42px
  }

  &__container {
    @include container();
  }

  h2 {
    margin-bottom: 0;
    @include md(margin-bottom, 0);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &__item {
    @include size(4);
    margin-bottom: 81px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 40px;
    justify-content: end;

    &:nth-child(3n+3) {
      margin-right: 0px;
    }

    @media (max-width: 1170px) {
      @include size(6);

      &:nth-child(3n+3) {
        margin-right: 40px;
      }

      &:nth-child(2n+2) {
        margin-right: 0px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(3n+3) {
        margin-right: 0px;
      }

      &:nth-child(2n+2) {
        margin-right: 0px;
      }
    }

    @include md(margin-bottom, 40px);
  }

  &__title {
    font-size: 28px;
    font-family: $font-CormorantGaramond-Bold;
    line-height: 121.429%;

    @media (max-width: 1700px) {
      font-size: 20px;
    }

    @media (max-width:768px) {
      font-size: 30px
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $main-color;
        ;
      }
    }

    b,
    strong {
      font-family: inherit;
    }
  }

  &__description {
    margin-top: 19px;
    line-height: 1.7;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width:768px) {
      margin-top: 15px;
    }
  }

  &__btn {
    font-family: $font-OpenSans-Bold;
    text-transform: uppercase;
    color: $main-color;
    position: relative;
    padding-left: 20px;
    margin-top: auto;
    padding-top: 25px;

    @media (max-width: 1024px) {
      padding-top: 17px;
    }

    @media (max-width: 768px) {
      font-size: 14px
    }

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 4px;
      top: 39px;
      border: 2px solid rgba(160, 119, 89, 1);
      border-radius: 100%;
      transition: .5s all;
      transform: translate(-50%, -50%);
      box-sizing: border-box;

      @media (max-width: 1024px) {
        top: 31px;
      }
    }

    &:hover {
      color: $main-color;

      &:after {
        width: 50px;
        height: 50px;
        border: 1px solid rgba(160, 119, 89, 0.5);
      }
    }
  }

  &__category {
    font-family: OpenSans-Semibold;
    line-height: 170%;
    margin-bottom: 20px;

    a {
      color: $additional-color;
      text-decoration: none;

      &:hover {
        color: $main-color;
        ;
      }
    }

    @media (max-width:768px) {
      font-size: 14px
    }
  }

  &__img {
    display: block;
    position: relative;
    margin-bottom: 39px;

    @media (max-width:768px) {
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 40%;

      @media (max-width:768px) {
        padding-top: 59%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      object-position: 50% 50%;
      object-fit: cover;
    }
  }
}

.cases__selects {
  margin: 80px -10px 79px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: auto;

  @media (max-width: 1700px) {
    margin-top: 60px;
    margin-bottom: 55px
  }

  @media (max-width: 1366px) {
    margin-top: 50px;
    margin-bottom: 45px
  }

  @media (max-width:768px) {
    margin-top: 43px;
    margin-bottom: 41px;
  }
}

.cases__select {
  display: inline-block;
  padding: 16px 32px;
  margin-right: 16px;
  border-radius: 100px;
  cursor: pointer;
  color: #725139;
  transition: color .8s ease;

  &:hover {
    color: $black-color;
  }
}

.cases__select--active {
  color: #fff;
  background: $main-color;
  pointer-events: none;
}

.why-choose {
  overflow: hidden;
  padding-bottom: 140px;
  @include md(padding-bottom, 80px);

  &__container {
    @include container();
    padding-right: 136px;

    @media (max-width: 1700px) {
      padding-right: 80px;
    }

    @media (max-width: 1024px) {
      padding-right: 20px;
    }
  }

  &__title {
    margin-bottom: 40px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 50px;
      left: 570px;
      width: 0;
      height: 1px;
      background-color: #a07759;

      @media (max-width: 1700px) {
        left: 500px;
      }

      @media (max-width: 1024px) {
        top: 25px;
        width: calc(100% - 140px);
      }
    }
  }

  &__text {
    width: 1005px;
    margin-bottom: 85px;
    @include lg(width, 100%);
    @include md(margin-bottom, 40px);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

  }

  &__item {
    @include size(6);
    display: flex;
    margin-bottom: 85px;

    &:nth-child(2n+1) {
      margin-right: 60px;
    }

    @media (max-width: 1170px) {
      margin-bottom: 40px;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 0px;

      &:nth-child(2n+1) {
        margin-right: 0px;
      }
    }

    @include md(margin-bottom, 40px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__rectangle {
    display: flex;
    min-width: 80px;
    height: 80px;
    background: linear-gradient(135deg, $secondary-color 50%, $main-color 50%);
    margin-right: 40px;
    align-items: center;
    justify-content: center;
    position: relative;

    @media(max-width: 1700px) {
      min-width: 60px;
      height: 60px;
    }

    @media(max-width: 1024px) {
      min-width: 40px;
      height: 40px;
      margin-right: 20px;
    }

    span {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      font-size: 64px;
      line-height: 20px;
      color: #fff;
      font-family: $font-Cormorant-Bold;

      @media(max-width: 1700px) {
        font-size: 46px;
      }

      @media(max-width: 1024px) {
        font-size: 36px;
      }
    }
  }

  &__desc {
    b {
      font-size: 24px;
      line-height: 40px;
      font-family: $font-OpenSans-Bold;
      margin-bottom: 20px;
      display: block;

      @media (max-width: 1700px) {
        font-size: 20px;
      }

      @include md(font-size, 16px);
      @include md(line-height, 22px);

    }
  }
}

.stages {
  overflow: hidden;
  margin-bottom: 100px;

  &__container {
    @include container();
  }

  &__title {
    width: 100%;
    margin-bottom: 40px;
    position: relative;
    @include md(margin-bottom, 34px);

    h2 {
      width: 672px;
      @include md(width, 100%);
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 40px;
        left: 663px;
        background-color: $main-color;
        width: 0%;
        height: 1px;

        @media (max-width: 1024px) {
          top: unset;
          bottom: 8px;
          left: unset;
          margin-left: 20px;
        }
      }
    }
  }

  &__text {
    margin-bottom: 80px;
    width: 1005px;
    @include md(margin-bottom, 40px);
    @include lg(width, 800px);
    @include md(width, 100%);
  }

  &__items {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__item {
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
      height: 80px;
      display: flex;
    }

    &:after {
      content: "";
      position: absolute;
      top: 30px;
      left: 59px;
      height: 3px;
      width: 0%;
      background-color: $main-color;
      transition: all 1s ease-out;

      @media (max-width: 1280px) {
        width: 65%;
      }

      @media (max-width: 1024px) {
        width: 65%;
      }

      @media (max-width: 768px) {
        height: 65%;
        width: 2px;
        left: 19px;
        top: 38px;
      }
    }

    &:last-child {
      margin-right: 20px;
      @include md(margin-right, 0);

      &:before {
        content: "";
        position: absolute;
        background-color: $main-color;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        left: 100%;
        top: 22px;
        transition: all 1s ease-out;
        transform: scale(0);

        @media (max-width: 1024px) {
          transform: unset;
        }

        @media (max-width: 768px) {
          width: 10px;
          height: 10px;
          left: 15px;
          top: 100%;
        }
      }
    }

    &--anim {
      .stages__icon {
        transition: all 1s ease-out;
        transform: scale(1.1);
      }

      &:after {
        width: 77%;

        @media (max-width: 1280px) {
          width: 65%;
        }
      }
    }

    &--anim-last {
      &:before {
        transform: scale(1) !important;
      }
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    background-color: $main-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);

    @media (max-width: 1024px) {
      transform: unset;
    }

    @media (max-width: 768px) {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }

    img {
      width: 30px;
      height: 30px;

      @media (max-width: 1700px) {
        width: 25px;
        height: 25px;
      }

      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__content {
    margin-right: 10px;
    width: calc(100% - 75px);
    line-height: 25px;
    margin-top: 32px;

    @media (max-width: 1700px) {
      width: calc(100% - 46px);
    }

    @media (max-width: 768px) {
      padding-left: 20px;
      margin-right: 0;
      font-size: 12px;
      line-height: 20px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 0;
      width: calc(100% - 60px);
    }
  }
}

.equipment {
  overflow: hidden;
  height: 675px;
  margin-bottom: 180px;
  margin-top: 233px;

  @media (max-width: 1700px) {
    margin-bottom: 0px;
    margin-top: 150px
  }

  @media (max-width: 1366px) {
    height: auto;
    margin-bottom: 80px;
    margin-top: 120px
  }

  &__container {
    @include container();
    position: relative;
  }

  &__item {
    display: flex;
    position: relative;
    overflow: hidden;
    padding-bottom: 80px;

    @media (max-width: 1366px) {
      flex-direction: column-reverse;
    }

    @media (max-width: 1024px) {
      padding-bottom: 50px;
    }
  }

  &__bg {
    position: relative;
    background: url("../../img/equipment/bg-slider.svg") no-repeat;
    background-size: 70.5% auto;
    width: 100%;
    height: 662px;

    @media (max-width: 1700px) {
      height: 430px;
      left: -144px;
    }
    @media (max-width: 1366px) {
      left: 0
    }

    @media (max-width: 768px) {
      left: 0;
    }

    @media (max-width: 1366px) {
      background: url("../../img/equipment/bg-slider-lg.jpg") no-repeat;
      width: 100%;
      background-position: center;
    }

    @media (max-width: 768px) {
      height: 380px;
    }

    &__img {
      img {
        width: 100%;
      }
    }

    &__content {
      position: absolute;
      top: 30px;
      right: 300px;
      display: flex;
      height: 100%;
    }
  }

  &__inner {
    position: absolute;
    bottom: 184px;
    left: 76px;

    @media (max-width: 1700px) {
      left: 0;
      bottom: 45px
    }

    @media (max-width: 1366px) {
      bottom: unset;
      left: 17%;
      top: 190px;
    }

    @media (max-width: 1170px) {
      left: 11%;
    }

    @media (max-width: 1024px) {
      left: 70px;
      top: 150px;
    }

    @media (max-width: 768px) {
      left: 20px;
      top: 40%;
    }
  }

  &__type {
    display: flex;
    flex-direction: column;
    width: 280px;
    line-height: 24px;
    font-size: 16px;
    position: absolute;
    top: 0;
    opacity: 0;

    @media (max-width: 1024px) {
      font-size: 12px;
      color: #fff;
    }

    &--active {
      opacity: 1;
    }

    b {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .slider-equipment {
    position: relative;

    &__wrapper {
      position: relative;
      height: 633px;
      width: 100%;

      @media (max-width: 1700px) {
        height: 430px;
      }

      @media (max-width: 768px) {
        height: 320px;
      }

    }

    &__item {
      position: absolute;
      bottom: 0;
      left: 22%;
      opacity: 0;
      transition: 1s all ease-in-out;

      &:nth-child(1) {
        left: 22%;
        bottom: -40px;

        @media (max-width: 1700px) {
          bottom: -80px;
          //left: 520px;
        }

        @media (max-width: 1366px) {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }

        @media (max-width: 768px) {
          bottom: -50px;
        }

        img {
          @media (max-width: 1700px) {
            width: 330px;
            height: 430px;
          }

          @media (max-width: 768px) {
            width: 264px;
            height: 340px;
          }
        }
      }

      &:nth-child(2) {
        left: 5%;
        bottom: -40px;

        @media (max-width: 1700px) {
          bottom: -80px;
             left: 15%;
        }

        @media (max-width: 1366px) {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }

      &:nth-child(3) {
        bottom: -40px;
          left: 3%;

        @media (max-width: 1700px) {
         left: 12%;
        }

        @media (max-width: 1366px) {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }

      &:nth-child(4) {
          left: 10%;
        @media (max-width: 1700px) {
             left: 13%;
        }

        @media (max-width: 1366px) {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }

      &:nth-child(5) {
        bottom: 0px;
        left: 12%;

        @media (max-width: 1700px) {
          //left: 300px;
          bottom: -55px;
        }

        @media (max-width: 1366px) {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:nth-child(6) {
        bottom: 10px;
          left: 13%;

        @media (max-width: 1700px) {
          //left: 0;
          bottom: -61px;
        }

        @media (max-width: 1366px) {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }

        img {
          @media (max-width: 768px) {
            height: 220px;
          }
        }
      }

      &--active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;

        @media (max-width: 1700px) {
          width: 560px;
          height: 430px;
        }

        @media (max-width: 768px) {
          width: 320px;
          height: 280px;
        }
      }
    }

    &__nav {
      position: absolute;
      top: 0;
      left: 55.5%;

      @media (max-width: 1366px) {
        left: 0;
      }

      @media (max-width: 1230px) {
        left: 40px;
        top: 10px;
      }

      @media (max-width: 1170px) {
        left: 10px;
      }

      @media (max-width: 1024px) {
        left: 75px;
        top: 130px;
      }

      @media (max-width: 768px) {
        left: 25px;
      }
    }

    &__dot {
      width: 12px;
      height: 12px;
      border: 2px solid $main-color;
      border-radius: 100%;
      margin-bottom: 18px;
      cursor: pointer;

      @media (max-width: 1230px) {
        border: 2px solid $white;
      }

      &:hover {
        background: $main-color;

        @media (max-width: 1230px) {
          background: $white;
        }
      }

      &--active {
        background: $main-color;

        @media (max-width: 1230px) {
          background: $white;
        }
      }
    }
  }

  .slider-equipment-button-next,
  .slider-equipment-button-prev {
    position: absolute;
    bottom: 130px;
    z-index: 100;
    width: 50px;
    height: 50px;
    cursor: pointer;

    @media (max-width: 1700px) {
      bottom: 0;
    }

    @media (max-width: 1366px) {
      bottom: -20px;
    }
  }

  .slider-equipment-button-next {
    left: 51.6%;
    background: url("../../img/icons/arrowLeftWhite.svg"), $white;
    background-repeat: no-repeat !important;
    background-position: center !important;

/*    @media (max-width: 1700px) {
      left: 832px;
    }

    @media (max-width: 1366px) {
      left: 700px;
    }*/

    @media (max-width: 1024px) {
      left: unset;
      right: 150px;
    }

    @media (max-width: 768px) {
      left: unset;
      right: 100px;
    }
  }

  .slider-equipment-button-prev {
    left: 54.7%;
    background: url("../../img/icons/ArrowRight.svg"), $main-color;
    background-repeat: no-repeat !important;
    background-position: center !important;

/*    @media (max-width: 1700px) {
      left: 882px;
    }

    @media (max-width: 1366px) {
      left: 750px;
    }*/

    @media (max-width: 1024px) {
      left: unset;
      right: 100px;
    }

    @media (max-width: 768px) {
      left: unset;
      right: 50px;
    }
  }

  &-desc {
    position: absolute;
    right: 0;
    top: 0;
    //width: 668px;
    width: 38%;

    @media (max-width: 1700px) {
      right: 0;
      //width: 527px;
      width: 42%;
    }

    @media (max-width: 1366px) {
      position: unset;
      width: 100%;
    }

    &__title {
      margin-bottom: 40px;

    }

    b {
      margin-bottom: 30px;
      display: block;
    }

    &__text {
      position: absolute;
      opacity: 0;

      &--active {
        opacity: 1;
        animation: 2s textSlide ease-in-out;
      }

      &--mob {
        display: none;
        padding-right: 80px;
        padding-left: 180px;
        margin-top: 40px;
        height: 100%;
        position: unset;
        opacity: 1;

        @media (max-width: 1024px) {
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      &--activeMob {
        @media (max-width: 1366px) {
          display: block;
        }
      }

      @keyframes textSlide {
        0% {
          opacity: 0;
        }

        50% {
          opacity: .5;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}

.companies {
  overflow: hidden;
  padding-bottom: 60px;

  @media (max-width: 1024px) {
    padding-bottom: 10px;
  }

  &__container {
    @include container();
  }

  &__title {
    width: 510px;
    margin-bottom: 80px;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  &__items {
    flex-wrap: wrap;
    row-gap: 80px;
    column-gap: 59px;
    justify-content: space-between;

    @media (max-width: 1700px) {
      row-gap: 60px;
      column-gap: 45px;
    }

    @media (max-width: 1024px) {
      row-gap: 20px;
      column-gap: 50px;
    }

    @media (max-width: 425px) {
      row-gap: 0;
      column-gap: 0;
    }
  }

  &__item {
    box-sizing: border-box;
    width: 120px;
    height: 120px;

    @media (max-width: 1700px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }

    @media (max-width: 425px) {
      margin-right: 50px;
      margin-bottom: 20px;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    img {
      box-sizing: border-box;

      @media (max-width: 1700px) {
        width: 100px;
        height: 100px;
      }

      @media (max-width: 768px) {
        width: unset;
        height: unset;
      }

      &:hover {
        border: 1px solid $main-color;
        box-sizing: border-box;
        display: flex;
      }
    }
  }
}

.consultation {
  position: relative;
  background: #000 url(/img/consultation.jpg) 0 0 no-repeat;
  background-size: cover;
  padding-bottom: 140px;
  padding-top: 140px;
  color: #fff;

  @media (max-width: 1366px) {
    padding-bottom: 110px;
    padding-top: 110px;
  }
  @media (max-width: 1280px) {
    padding-bottom: 80px;
  }

  @media (max-width: 1024px) {
    padding-top: 41px;
    padding-bottom: 40px;
  }

  &__container {
    @include container();
    justify-content: space-between;

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }

  }

  &__title {
    padding-top: 41px;
    width: 580px;
    margin-bottom: 40px;
    position: relative;
    font-family: $font-CormorantGaramond-Bold;
    letter-spacing: .8px;
    -moz-font-weight: normal;
    -webkit-font-weight: normal;
    font-weight: 700;

    @media (max-width: 1700px) {
      width: 525px;
    }

    @media (max-width: 1280px) {
      width: 100%;
      margin-bottom: 20px;
    }

    @media (max-width: 1280px) {
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      padding-top: 25px;
      margin-bottom: 26px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0px;
      height: 1px;
      background: $main-color;
    }
  }

  &__text {
    width: 400px;

    @media (max-width: 1280px) {
      width: 100%;
    }

  }

  &__desc {
    @media (max-width: 1700px) {
      margin-right: 80px;
    }

    @media (max-width: 1280px) {
      width: 100%;
      margin-bottom: 40px;
      margin-right: 0;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      border-top: 1px solid $main-color
    }
  }

  &__form {
    width: 680px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .form-const {
      &__input {
        width: 100%;
        height: 80px;
        margin-bottom: 30px;

        @media (max-width: 1700px) {
          height: 60px;
        }

        @media (max-width: 1280px) {
          margin-bottom: 15px;
        }

        input {
          padding-left: 40px;
          color: $gray-color;
          border: 1px solid #fff;

          @media (max-width: 480px) {
            padding-left: 17px;
          }

          &:hover,
          &:focus {
            border: 1px solid $secondary-color;
          }

          &::placeholder {
            color: $gray-color;
          }
        }

        &--error {
          border: 1px solid $error;
        }

      }

      &__error {
        color: $error;
        margin-top: -20px;
        display: none;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        margin-bottom: 20px;
        position: relative;
        top: -9px;
      }

      &__btn {
        text-align: right;

        @media (max-width: 1280px) {
          margin-top: 22px;
          text-align: left;
        }
      }
    }
  }
}

.news {
  margin-top: 198px;
  padding-bottom: 0;
  background: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding-bottom: 20px;
    margin-top: 63px;
  }

  &__container {
    @include container();
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 78px -30px 0;

    @media (max-width: 1440px) {
      margin: 58px -20px 0;
    }

    @media (max-width: 767px) {
      margin: 0
    }
  }

  &__item {
    margin: 0 30px 60px;
    width: calc(33.333% - 60px);
    padding-bottom: 20px;
    color: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1440px) {
      margin: 0 20px 55px;
      width: calc(33.333% - 40px);
    }

    @media (max-width: 1024px) {
      margin-bottom: 50px;
      width: calc(50% - 40px);
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 19px
    }
  }

  &__img {
    display: block;

    img {
      width: 100%;
      object-fit: cover;
      height: auto;
      vertical-align: top
    }
  }

  &__desc {
    padding: 47px 0 0;

    @media (max-width: 1700px) {
      padding-top: 28px
    }

    @media (max-width: 1024px) {
      padding: 17px 0 0;
    }

    h3 {
      margin-bottom: 20px;
      font-size: 28px;
      font-family: $font-CormorantGaramond-Bold;
      line-height: 121.429%;
      letter-spacing: 0.8px;

      @media (max-width: 1366px) {
        font-size: 22px
      }

      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }

      @media (max-width: 767px) {
        font-size: 30px
      }

      a {
        color: inherit;
      }
    }
  }

  &__text {
    line-height: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width: 767px) {
      font-size: 16px
    }
  }

  &__date {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.32px;
    color: $gray-color;
    margin-bottom: 27px;

    @media (max-width: 1700px) {
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      margin-bottom: 13px;
    }
  }

  &__btn {
    margin-top: 23px;
  }
}

.box-pointer {
  position: fixed;
}

.box-pointer-hidden {
  display: none;
}

.box-pointer2 {
  width: 300px;
  height: 200px;
  left: 10px;
  right: 0px;
  top: 15px;
  transition: 0ms cubic-bezier(0.1, 0.57, 0.1, 1);
  z-index: 1000;

  @media (max-width: 1700px) {
    width: 260px;
    height: 160px;
  }
}

.swiper-reviews {
  margin-left: -23px;
}

.reviews {
  padding: 123px 0 106px;

  @media (max-width: 1700px) {
    padding: 90px 0 56px;
  }

  @media (max-width:1023px) {
    padding: 78px 0 17px;
  }

  &__item {
    padding-left: 23px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &.full {
      .reviews__author {
        display: flex;
      }

      .reviews__btn {
        display: none;
      }
    }
  }

  &__top {
    display: flex;
  }

  &__title {
    font-family: $font-CormorantGaramond-Bold;
    font-size: 28px;
    line-height: 121.429%;
    letter-spacing: 0.8px;

    @media (max-width: 1700px) {
      font-size: 22px
    }

    @media (max-width: 1024px) {
      font-size: 20px
    }

    @media (max-width: 768px) {
      font-size: 30px
    }
  }

  &__description {
    margin-top: 44px;
    //max-height: 93px;
    overflow: hidden;

    @media (max-width: 1700px) {
     // max-height: 84px;
    }

    @media (max-width: 1024px) {
      //max-height: 75px;
      margin-top: 25px;
    }
  }

  &__text {
    line-height: 1.7;
  }
  .over {
    .reviews__text{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .reviews__title{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__bottom {
    margin-top: 38px;

    @media (max-width: 768px) {
      margin-top: 29px;
    }
  }

  &__author {
    display: none;

    span {
      flex-shrink: 0;
      max-width: 77%
    }

    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      flex-shrink: 10;
      margin: 15px 0 0 40px;
      background: $main-color;
      @media (max-width: 767px) {
          margin: 14px 0 0 20px;
          }
    }
  }
}

.quote-icon {
  height: 40px;
  width: 40px;
  background: url(/img/icons/quote.svg) 0% 50% no-repeat;
  background-size: contain;
  display: inline-block;
  flex-shrink: 0;
  margin-right: 45px;
  position: relative;
  z-index: 0;
  top: 13px;

  @media (max-width: 1700px) {
    height: 35px;
    width: 35px;
    margin-right: 35px;
  }

  @media (max-width: 1024px) {
    height: 30px;
    width: 30px;
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    height: 43px;
    width: 40px;
    margin-right: 20px;
    margin-top: -17px;

  }
}

.section-header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    display: block;
    border-bottom: 1px solid $main-color;
    padding-bottom: 25px;
  }

  .h2-title {
    flex-shrink: 0;
    max-width: 60%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__line {
    margin-left: 40px;
    width: 100%;
    height: 1px;
    background-color: #a07759;
    transform: scale(0);
    transition: transform 1s ease;
    transform-origin: 0 0;
    flex-shrink: 10
  }

  &.animate-el {
    .section-header__line {
      transform: scale(1);
    }
  }

  .btn {
    margin-left: 40px;
  }
}

.review-popup {
  background: #fff;
  padding: 60px 60px 40px;
  display: none;
  opacity: 0;
  border-bottom: 2px solid $main-color;
  transition: opacity 1s ease;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  max-width: 100%;
  width: 910px;

  @media (max-width: 768px) {
    padding: 60px 24px 40px;
  }

  &.open {
    display: block;
    opacity: 1;

    &~.overlay {
      opacity: 1;
      display: block;
    }
  }

  .reviews__item {
    padding-bottom: 0;
    padding-left: 0;

  max-height: calc(100vh - 185px);
  overflow: auto;
  }

  .reviews__btn {
    display: none;
  }

  .reviews__author {
    display: flex;
  }

  .reviews__description {
    max-height: none;
    margin-top: 26px;

    @media (max-width: 768px) {
      margin-top: 14px;
    }
  }

  .reviews__top {
    padding-right: 90px;
    position: relative;
    align-items: flex-start;
    @media (max-width: 768px) {
      padding-right: 15px;
      display: block;
    }
  }

  .reviews__text {
    p {
      margin: 25px 0;
    }
  }

  .reviews__bottom {
    margin-top: 38px;
  }

  .quote-icon {
    position: relative;
    height: 42px;
    top: -3px;
    left: 0;
    width: 39px;
    @media (max-width: 768px) {
      height: 25px;
              top: 7px;
        left: 26px;
      width: 28px;
      margin-right: 20px;
      margin-top: 20px;
    }
  }
@media (max-width: 768px) {
  .reviews__top .quote-icon {
    display: none;
  }

  .quote-icon {
    position: absolute;
  }
}
@media (min-width: 769px) {
  .quote-icon_popup {
    display: none;
  }
}
}
  .overlay {
    background: rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    transition: opacity 1s ease;
    position: fixed;
    left: 0%;
    top: 0%;
    z-index: 1000
  }

  .close-popup {
    position: absolute;
    right: 62px;
    top: 54px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    background-image: url(../../img/icons/close-2.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    z-index: 2;

    @media (max-width: 768px) {
      right: 18px;
      top: 19px;
      height: 30px;
      width: 30px;
    }
  }
  @media (max-width:1023px) {

    .swiper-button-next,
    .swiper-button-prev {
      width: 20px;
      height: 32px;
    }
  }
  .news-main {
    margin-top: 0;
    .news__title {
      margin-bottom: 80px;
  @media (max-width:1024px) {
      margin-bottom: 40px;
    }
    }
  }