.case-page {
  &__container {
    @include container();
    @media (max-width: 1700px) {
      padding-right: 80px;
    }
    @media (max-width: 1366px) {
      padding-right: 20px;
    }
  }
  &__content {
    padding-top: 80px;
    padding-bottom: 80px;
    max-width: 1274px;
    margin: 0  auto;
    @media (max-width: 1024px) {
      display: block;
    }
    @media (max-width: 768px) {
      padding-top: 40px;
      padding-bottom: 0;
    }
  }
  &__specialist {
    position: relative;
    width: 340px;
    @media (max-width: 1024px) {
      margin: 77px auto 0;
    }
    &-wrap {
      position: sticky;
      top: 120px;
    }
    &__img {
      position: relative;

    }
    &__inner {
      margin-top: 29px;
      display: flex;
      flex-direction: column;
    @media (max-width: 1024px) {
      margin-top: 37px;
    }
    }
    &__name {
      font-family: $font-OpenSans-Semibold;
      font-size: 14px;
      letter-spacing: 0.32px;
    }
    &__position {
      margin-bottom: 5px;
      font-size: 16px;
    }
    &__socials {
      position: absolute;
      bottom: 0;
      right: 0;
      ul {
        display: flex;
        li {
          margin-right: 10px;
          img {
            max-width: 16px;
            max-height: 30px;
          }
          a {
            background: $main-color;
            width: 30px;
            height: 30px;
            display: flex;
            border-radius: 50%;
            justify-content: center;
          }
        }
      }
    }
  }
  &__desc {
    width: calc(100% - 340px);
    padding-right: 80px;
    @media (max-width: 1700px) {
      padding-right: 60px;
    }
    @media (max-width: 1366px) {
      padding-right: 40px;
    }
    @media (max-width: 1024px) {
      padding-right: 0px;
      width: 100%;
      padding-right: 0;
    }
    h2 {
      margin-bottom: 40px;
      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
    p {
      margin-bottom: 30px;
      @media (max-width: 1024px) {
               margin-bottom: 15px;
      }
    }
    img {
      margin-bottom: 80px;
      margin-top: -40px;
      width: 100%;
      object-fit: cover;
      @media (max-width: 1024px) {
        margin-bottom: 40px;
        margin-top: -20px;
      }
      @media (max-width: 480px) {
        height: 150px;
      }
    }
    ul {
      padding-left: 40px;
      margin-top: 15px;
      li {
        position: relative;
        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }
        &:before {
          content: "";
          background: url("../../img/icons/elipse-3.svg");
          width: 12px;
          height: 12px;
          position: absolute;
          top: 12px;
          left: -25px;
        }
      }
    }
  }
  .breadcrumb__item  {
    a {
    color: $gray-additional;
  }
      &:last-child {
      a {
        color: $gray-color;
      }
    }
  }
  h3 {
    font-family: $font-CormorantGaramond-Bold;
    font-size: 54px;
    line-height: 120.37%;
    letter-spacing: 0.8px;
    margin-bottom: 25px;
      @media (max-width: 1366px) {
        font-size: 44px;
      }

      @media (max-width: 1024px) {
        font-size: 34px;margin-top: 30px;
      }
  }
  a {
    text-decoration: underline;
    font-family: $font-OpenSans-Semibold;
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  &__quote {
    padding-bottom: 15px;
    margin-top: 110px;
    @media (max-width: 1700px) {
        margin-top: 52px;
    }
    &__text {
      font-family: $font-CormorantGaramond-Bold;
      font-size: 28px;
      line-height: 40px;
          margin-top: 95px;
      padding-left: 106px;
      padding-right: 102px;
      position: relative;
      @media (max-width: 1700px) {
        margin-top: 68px;
      }
      @media (max-width: 1024px) {
        font-size: 30px;
        line-height: 120%;
        margin-top: 50px;
      }
      @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 50px;
      }
      &:before {
        content: "\201c";
        font-size: 144px;
        line-height: 174px;
        color: $main-color;
        position: absolute;
        top: -40px;
        left: 20px;
        @include md(font-size, 72px);
        @include md(line-height, 87px);
        @include md(top, -30px);
        @include sm(left, 0px);
      }
    }
    &-description {
      margin-top: 59px;
        @include md(margin-top, 26px);
      p {
        margin:26px 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-top: 0;
        }
        @include md(margin, 13px 0);
      } 
    }
  }
}
.call-action {
  position: relative;
  background-color: $main-color;
  padding-top: 70px;
  padding-bottom: 70px;
  @media (max-width: 1366px) {
    padding-top: 30px;
    padding-bottom: 38px;
  }
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../img/call-bg-left.svg") no-repeat;
    width: 600px ;
    height: 100%;
    @media (max-width: 1024px) {
      background: url("../../img/call-bg-mob.svg") no-repeat;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: url("../../img/call-bg-right.svg") no-repeat;
    width: 420px;
    height: 100%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__container {
    @include container();
    position: relative;
    z-index: 1;
    justify-content: space-between;
    @media (max-width: 1612px) {
      padding-right: 20px;
    }
    @media (max-width: 1366px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__desc {
    color: $white;
    @media (max-width: 1366px) {
      margin-bottom: 10px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
      h1 {
      margin-bottom: 40px;
        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }
    }
    p {
      width: 300px;
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
}
.form-call-me {
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
  &__input {
    width: 240px;
    height: 60px;
    margin-right: 20px;
    @media (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
      height: 40px;
    }
    input {
      width: 100%;
      padding-left: 20px;
      background: rgba(0,0,0, 0);
      border: 1px solid #D9BEA4;
      color: $white;
      &::placeholder {
        color: $white;
      }
    }
  }
  button {
    background-color: $white;
    @media (max-width: 1024px) {
      width: 40px;
      height: 40px;
      margin-left: auto;
    }
  }
}
.similar {
  margin-top: 141px;
    margin-bottom: 62px;
  @media (max-width: 1024px) {
    margin-top: 80px;
    margin-bottom: 20px;
  }
}
.case-wrapper {
  @include container();
  @include pr();
  display: flex;
  align-items: center;
  margin-bottom: 175px;
  position: relative;

  @media (max-width: 1700px) {
    margin-bottom: 68px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 130px;
  }

}
.comment-desc {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  span {
    flex-shrink: 0;
  }
  .line {
    margin-left: 20px;
    display: flex;
    width: 100%;
    height: 1px;
    transform: scale(0);
    background-color: $main-color;
    transition:  transform 1s ease;
    transform-origin: 0 50%; 
    align-items: center;
    &.animate-line-case {
      transform: scale(1);
    }
  }
  @media (max-width: 1612px) {
    padding-right: 20px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
}
.section-header__sub {
  color:$gray-color;
  margin-bottom: 35px;
  font-size: 24px;
  @media (max-width: 1024px) {
    font-size: 18px;
    margin-bottom: 13px;
  }
}
.section-service-2 {
  @media (max-width: 1440px) {
    &.section-checklist {
      padding-bottom: 20px
    }
  }
  @media (max-width: 1024px) {
    padding: 20px 0 4px;
  }
  @media (max-width: 1023px) {
    padding-bottom: 10px;
    &.section-checklist:before {
      display: none;
    }
  }
}