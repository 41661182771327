.team {
  &__inner {
        width: 53%;
    @media (max-width: 1700px) {
      padding-right: 20px;
    }
    @media (max-width: 1024px) {
      padding-right: 0;
      width: 100%;
    }
  }

    strong, b {
      font-family: "OpenSans-Bold";
    }
  &__title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    font-family: CormorantGaramond-Bold;
    letter-spacing: .8px;

    @media (max-width: 1700px) {
      font-size: 48px;
      line-height: 58px;
    }

    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 34px;
    }
  }

  &__description {
    line-height: 170%;
    p {
      margin: 20px 0;
    @media (max-width: 1024px) {
      margin: 13px 0
    }
    }
    b, strong {
      font-weight: 700 !important
    }
  }
  &__top-b {
    @include container();
    display: flex;
    margin-top: 72px;

    @media (max-width: 1024px) {
      margin-top: 40px;
    }
    .h-team  {
      margin-top: 8px;
      margin-bottom: 38px;
    @media (max-width: 1024px) {    margin-bottom: 22px;
    }
    }
    h2 {
      margin-bottom: 30px;

      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }


    &__icon {
      padding: 59px 0 0 9%;
      width: 37%;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 1366px) {
        display: none;
      }
      img {
        max-width: 100%
      }
    }
  }
  &__mid-b {
    @include container();
    display: flex;
    margin-top: 66px;



    @media (max-width: 1366px) {
      flex-direction: column;
      align-items: center;
      flex-direction: column-reverse;
    }

    @media (max-width: 1024px) {
      margin-top: 40px;
      display: flex;
      flex-direction: column-reverse;
    }

    &__img {
    padding-right: 6%;
    width: 59%;
      @media (max-width: 1366px) {
        padding-right: 0;
      }

      @media (max-width: 1024px) {
        width: 100%;
        text-align: center
      }

      img {
        width: 100%;
      }
    }

    h3 {
      margin-bottom: 38px;


      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }

    &__inner {
          width: 41%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &__bottom-b {
    @include container();
    margin-top: 34px;

    @media (max-width: 1024px) {
      margin-top: 40px;
    }

    h3 {
      margin-bottom: 38px;

      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
    &__inner {
      max-width: 854px;
      @media (max-width: 1700px) {
            max-width: 600px;
    }
    }
  }
  &__container {
    @include container();
  }
  &-specialists {
    overflow: hidden;
    padding-top: 160px;
    padding-bottom: 160px;

    @media (max-width: 1366px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    @media (max-width: 1024px) {
              padding-top: 39px;
      padding-left: 0;
    }


    &__header {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1366px) {
        margin-bottom: 80px;
      }

      @media (max-width: 1024px) {
        margin-bottom: 40px;
      }
    @media (max-width: 768px) {
      border-bottom: 1px solid $main-color;
      padding-bottom: 24px;
    }
    }
    .swiper-container {
      overflow: visible;
    }
    &__nav {
      position: relative;
      width: 150px;

      @media (max-width: 480px) {
        width: 100px;
      }

      .swiper-button-next,
      .swiper-button-prev {
        outline: none;
      }

      .swiper-button-next {
        background-image: url("/img/specialist/Icon/arrow.svg");
      }

      .swiper-button-prev {
        background-image: url("/img/specialist/Icon/arrow.svg");
        transform: rotate(180deg);
      }

      .swiper-button-next.swiper-button-disabled {
        background-image: url("/img/specialist/Icon/arrow-def.svg");
        transform: rotate(180deg);
      }

      .swiper-button-prev.swiper-button-disabled {
        background-image: url("/img/specialist/Icon/arrow-def.svg");
        transform: rotate(0deg);
      }

    }

    &__item {
      position: relative;
      margin-bottom: 20px;
    }

    .swiper-slide {
      display: inline-table;

      &--none {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__img {
      width: 340px;
      height: 450px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        height: 32px;
        width: 102%;
        background: #fff;
        z-index: 1;
        transition: .1s all ease-in-out;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      @media (max-width: 1024px) {
        width: 230px;
        height: 300px;
      }

      &:hover {
        &:before {
          height: 0;
          transition: .4s all ease-in-out;
        }
      }

      img {
        width: 340px;
        height: 450px;
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
        /* Firefox 10+, Firefox on Android */
        filter: gray;
        /* IE6-9 */
        -webkit-filter: grayscale(1);
        /* Google Chrome, Safari 6+ & Opera 15+ */
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        &:hover {
          filter: none;
          -webkit-filter: grayscale(0);
        }

        @media (max-width: 1024px) {
          width: 230px;
          height: 300px;
          -ms-filter: unset;
          filter: unset;
        }
      }
    }

    &__socials {
      position: absolute;
      bottom: -10px;
      right: 0;

      ul {
        display: flex;

        li {
          margin-right: 10px;

          a {
            width: 30px;
            height: 30px;
            background-color: $main-color;
            border-radius: 50%;
            display: flex;
            justify-content: center;

            img {
              width: 15px;
            }
          }
        }
      }
    }

    &__desc {

      p {
        line-height: 24px;
        color: $gray-additional;
      }

      b {
        font-family: $font-OpenSans-Bold;
        line-height: 40px;
      }
    }
  }
}
.h-team  {
  font-size: 24px;
  color: $main-color;
  line-height: 170%;
  border-bottom: 2px solid $additional-color;
  padding: 0 0 20px;
  margin-bottom: 20px;
  line-height: 1.2;
  font-family: $font-OpenSans-Regular;
  letter-spacing: .1px;

  b {
    line-height: 110.37%;
    letter-spacing: 0.8px;
    font-size: 54px;
    display: block;
    margin-bottom: -6px;
    font-family: $font-CormorantGaramond-Bold;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }

  @media (max-width: 1024px) {
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 23px;
    padding: 0 0 13px;
  }
}
.history {
  padding-bottom: 126px;
      @media (max-width: 1024px) {
padding-bottom: 13px;
}
  &__container {
    @include container();

    h2 {
      padding-left: 136px;

      @media (max-width: 1700px) {
        padding-left: 80px;
      }

      @media (max-width: 1024px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &__content {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    margin-left: -1px;

    @media (max-width: 1366px) {
      flex-direction: column;
    }

    @media (max-width: 1024px) {
      margin-top: 40px;
    }
  }

  &__img {
    margin-right: 60px;

    @media (max-width: 1366px) {
      display: flex;
      justify-content: center;
      margin-right: 0;
      margin-bottom: 40px;
    }

    img {
      @media (max-width: 1700px) {
        width: 590px;
        height: fit-content;
        object-fit: cover;
      }

      @media (max-width: 768px) {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text {
    word-wrap: break-word;
    width: 52%;
    padding-top: 20px;

    @media (max-width: 1366px) {
      width: 100%;
    }

    p {
      word-wrap: break-word;

      &:first-child {
        margin-bottom: 40px;
        display: block;
      }
    }

  }
}

.accordion {
  &__container {
    @include container();
  }

  &__item {
    margin-bottom: 60px;
    position: relative;

    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  &__header {
    border-bottom: 1px solid $gray-color;
    display: flex;
    position: relative;
    padding: 10px 0;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    b {
      margin-right: 80px;
      font-family: $font-CormorantGaramond-Bold;
      font-size: 54px;
      line-height: 60px;

      @media (max-width: 768px) {
        font-size: 28px;
        margin-right: 0;
        margin-bottom: 20px;
        line-height: 20px;
      }
    }

    h3 {
      line-height: 38px;
      font-family: $font-OpenSans-Regular;
      font-weight: 400;
      padding-right: 30px;
      position: relative;
      width: 100%;

      &:after {
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../img/icons/chevron.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 20px;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }
    }

    &--active {
      border-bottom: 1px solid $secondary-color;

      h3 {
        color: $secondary-color;

        &:after {
          background-image: url("../../img/icons/chevron-active.svg");
        }
      }
    }
  }


  &__content {
    display: none;

  }

  &__wrapper {
    display: none;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 180px;
    display: flex;

    @media (max-width: 1366px) {
      padding-left: 20px;
    }

    @media (max-width: 1024px) {
      padding-top: 20px;
      padding-bottom: 0;
    }
  }

  &__img {
    width: 400px;
    height: 280px;
    margin-left: 80px;

    @media (max-width: 1024px) {
      display: none;
    }

    img {
      width: 400px;
      height: 100%;
      object-fit: cover;

      @media (max-width: 1700px) {
        width: 300px;
        height: unset;
      }
    }
  }

  &__text {
    p {
      margin-bottom: 15px;
    }
  }
}

.certificates {
  padding-top: 160px;
  padding-bottom: 160px;
  overflow: hidden;

  @media (max-width: 1366px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__header {
    margin-bottom: 130px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1366px) {
      margin-bottom: 80px;
    }

    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  &__nav {
    position: relative;
    width: 150px;

    @media (max-width: 480px) {
      width: 100px;
    }
    .swiper-certificates {
      overflow: visible;
    }
    .swiper-button-next-c,
    .swiper-button-prev-c {
      outline: none;
      position: absolute;
      top: 50%;
      width: 27px;
      height: 44px;
      margin-top: -22px;
      z-index: 10;
      cursor: pointer;
      background-size: 27px 44px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .swiper-button-next-c {
      right: 10px;
      left: auto;
      background-image: url("/img/specialist/Icon/arrow.svg");
    }

    .swiper-button-prev-c {
      right: auto;
      left: 10px;
      background-image: url("/img/specialist/Icon/arrow.svg");
      transform: rotate(180deg);
    }

    .swiper-button-next-c.swiper-button-disabled {
      background-image: url("/img/specialist/Icon/arrow-def.svg");
      transform: rotate(180deg);
    }

    .swiper-button-prev-c.swiper-button-disabled {
      background-image: url("/img/specialist/Icon/arrow-def.svg");
      transform: rotate(0deg);
    }
  }

  .swiper-certificates {
    width: 1780px;

    @media (max-width: 1024px) {
      width: 768px;
    }

    @media (max-width: 640px) {
      width: 510px;
    }
  }

  &__img {
    width: 560px;

    @media (max-width: 1024px) {
      width: 230px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.quote-team {
  padding: 102px 0 18px;
  position: relative;
    @media (max-width: 1024px) {
  padding: 67px 0 9px;
}
  &:before {
    content: '';
    display: block;
    z-index: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -300px;
    background: url(../../img/bg-img-3.png) 50% 100% no-repeat;
    background-size: 100% auto;
  }
  .container {
    position: relative;
    z-index: 1
  }
  .advantages__quote {
    padding: 0;
    margin-top: 0
  }
}
.section-quote-advantages {
  background: #000 url(/img/quote-advantages.jpg) 0 0 no-repeat;
  background-size: cover;
  padding: 120px 0 80px;
  color: #fff;
    @media (max-width: 1024px) {
      padding: 22px 0 3px;
    }
}
.quote-advantages {
  color: $additional-color;
  margin: 78px -40px 0;
  display: flex;
  flex-wrap: wrap;
    @media (max-width: 1024px) {
      margin-top: -10px
    }
  &__item {
    padding: 0 40px;
    margin: 40px 0;
    width: 33.333%;
    @media (max-width: 1024px) {
      width: 50%;
          margin: 20px 0;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  &__icon {
    height: 40px;
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
    margin-bottom: 19px;
  }
  }
  &__title {
    font-family: $font-CormorantGaramond-Bold;
    font-size: 40px;
    line-height:  115%;
    letter-spacing: 0.8px;
    @media (max-width: 1700px) {
      font-size: 35px;
    }
    @media (max-width: 1366px) {
      font-size: 30px;
    }
  }
  &__description {
    line-height: 170%;
    margin-top: 20px;
    @media (max-width: 1024px) {
    margin-top: 6px;
  }
  }
}