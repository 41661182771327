.pagination {
  padding-bottom: 160px;
  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }
  &__container {
    @include container();
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1612px) {
      padding-right: 20px;
    }
  }
  &__news {

  }
  &__item {
    width: 40px;
    height: 40px;
    border: 1px solid #EFEFEF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    &:hover {
      border-bottom: 1px solid $main-color;
    }
    a {
      color: $main-color;
      width: 100%;
      text-align: center;
    }
    &--active {
      border: 1px solid $main-color;
      background-color: $main-color;

      a {
        color: $white;
      }
    }
    &--out-of-range {
      border: none;
      margin-right: 0;
      margin-left: -10px;
      &:hover {
        border-bottom: none;
      }
      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        svg {
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}