* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  font-family: $font-OpenSans-Regular;
  font-size: 18px;
  line-height: 170%;
  color: $black-color;
  //letter-spacing: .32px;
  background-color: #ffffff;
  max-width: 1920px;
  margin: 0 auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @media(max-width: 1700px) {
    font-size: 16px;
  }

}
b, strong {
  font-family: $font-OpenSans-Bold
}
main {
  position: relative;
  z-index: 2;
  margin-top: 128px;
  @media (max-width: 1700px) {
    margin-top: 100px;
  }
  @media (max-width: 1024px) {
    margin-top: 0;
  }
}
input, textarea, select {
  font-size: 18px;
  width: 100%;
  height: 100%;
  @media (max-width: 1700px) {
    font-size: 16px;
  }
  @include md(font-size, 100%);
  outline:  none;
}
h1,h2, .h2-title, h3,h4,h5,h6 {
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
  padding: 0;
}
.wrapper {
  display: flex;
  position: relative;
  z-index: 100;
}
.page {
  position: relative;
 //overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(../../img/main-slide-bg.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 100% auto;
}
.menu-desc--services {
  @media(max-width: 1900px) {
    width: 620px;
    height: 340px;
  }
  @media(max-width: 1700px) {
    display: none!important;
    opacity: 0;
  }
}
ul{
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
a {
  text-decoration: none;
}
button {
  outline: none;
  border: none;
}
h2 {
  font-family: $font-CormorantGaramond-Bold;
  font-size: 54px;
  line-height: 65px;
  letter-spacing: .8px;
  -moz-font-weight: normal;
  -webkit-font-weight: normal;
  font-weight: 700;
  @media(max-width: 1700px) {
    font-size: 48px;
    line-height: 58px;
  }
  @media(max-width: 1024px) {
    font-size: 34px;
    line-height: 1.2;
    letter-spacing: 0;
  }
}
.h2-title {
  font-family: $font-CormorantGaramond-Bold;
  font-size: 54px;
  line-height: 65px;
  letter-spacing: .8px;
  -moz-font-weight: normal;
  -webkit-font-weight: normal;
  font-weight: 700;
  @media(max-width: 1700px) {
    font-size: 48px;
    line-height: 58px;
  }
  @media(max-width: 1024px) {
    font-size: 34px;
    line-height: 1.2;
    letter-spacing: 0;
  }
}
h3 {
  font-family: $font-CormorantGaramond-Bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: .8px;
  -moz-font-weight: 400;
  -webkit-font-weight: normal;
  font-weight: 700;
  @media(max-width: 1700px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media(max-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }
}
h3 {
  -moz-font-weight: normal;
  -webkit-font-weight: normal;
  font-weight: 700;

}
@-moz-document url-prefix() {
  h1, h2, h3, .title, b, .h2-title {
    font-weight: 400!important;
  }
}
body:last-child:not(:root:root) h1, h2, h3, .title, b, .h2-title {
  font-weight: 400!important;
}

.title {
  font-family: $font-CormorantGaramond-Bold;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: .8px;
  -moz-font-weight: normal;
  -webkit-font-weight: normal;
  font-weight: 700;
  @media(max-width: 1700px) {
    font-size: 48px;
    line-height: 58px;
  }
  @media(max-width: 1024px) {
    font-size: 34px;
    line-height: 1.2;
    letter-spacing: 0;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.d-flex {
  display: flex;
}
.d-flex-sm {
  display: none !important;
   @include sm(display, flex)
}
.d-flex-md {
  display: none;
   @include md(display, flex)
}
.d-none-sm {
  @include sm(display, none !important)
}
.d-none-md {
  @include md(display, none)
}
.d-none-lg {
  @include lg(display, none)
}
@mixin container(){
    max-width: 1920px;
    margin: 0 auto;
    @include container-full();
    width: 100%;
}
.container-full {
  overflow: hidden;
 // @include container-full();
  //padding-left: 154px;
  position: relative;
  overflow: hidden;
  @media  (max-width: 1700px) {
    //padding-left: 120px;
  }
  @media  (max-width: 1024px) {
    //padding-left: 0px;
  }
  @media  (max-width: 768px) {
    padding-top:66px;
    display: flex;
    flex-direction: column;
/*    .breadcrumb {
      position: absolute;
      top: 58px;
      left: 20px;
    }*/
  }

    .container-full__wrapper {
      height: 100%;
      padding-top: 29px;
      padding-bottom: 99px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 1920px;
  @media  (max-width: 768px) {
    padding-bottom: 20px;
        padding-top: 10px;
  }
    }
    .container-full__desc {
      flex-grow: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      strong, b {
        font-family: "OpenSans-Bold";
      }
    }
    .breadcrumb {
      flex-shrink: 0
    }
  &__img {
    margin-bottom: -11px;
    @media (max-width: 768px) {
      //position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 480px) {
        //height: 150px;
      }
    }
    &--cases {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        @media (max-width: 768px) {
          content: none;
        }
      }
    }

    &--fix {
    @media (max-width: 768px) {
      position: absolute;
    }

      img {
        height: 495px;

        @media (max-width: 768px) {
          height: 100%;
        }
      }
    }
  }
  &__wrapper {
    @include  container();
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      position: relative;
      transform: none;
      top: 0px;
      left: 0;
    }
    &--service {
      @media (max-width: 768px) {
        //position: unset;
      }
    }
  }
  &__desc {
    color: $white;
    width: 600px;
    @media (max-width: 768px) {
     color: $black-color;
      width: 100%;
      margin-top: 20px;
      padding-bottom: 0;
      .title {
        margin-bottom: -4px;
      }
    }
    span {
      font-family: OpenSans-Semibold;
      color: $additional-color;
      display: block;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        color: #725139;
        line-height: 19px;
        margin-bottom: 27px;
        display: block;
      }
    }
    p {
      margin-top: 30px;
    }
  }
}
.form-const__error {
  color: #961818;
  margin-top: -15px;
  display: none;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
}
.form-const__input--error {
  input {
    border: 1px solid $error!important;
  }
}

blockquote {
  margin: 0;
  padding: 0;
}
.overflow-hiddenn {
  overflow: hidden;
}
.animate-line {
  &:after {
    transition: 1s all ease-in-out;
    width: 100%!important;
  }
}
.animate-line-lg {
  &:after {
    transition: 1s all ease-in-out;
    width: 150%!important;
  }
}
.animate-line-consult {
  &:after {
    transition: 1s all ease-in-out;
    width: 412px!important;
  }
}
.animate-line-news {
  &:after {
    transition: 1s all ease-in-out;
    width: calc(100% - 250px)!important;
  }
}
.animate-line-why {
  &:after {
    transition: 1s all ease-in-out;
    width: calc(100% - 250px)!important;
    @media (max-width: 1700px) {
      width: calc(100% - 140px)!important;
    }
  }
}
.animate-line-case {
  &:after {
    transition: 1s all ease-in-out;
    width: calc(100% - 20px)!important;
  }
}
.thank-form {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(144, 144, 144, .3);
  position: fixed;
  z-index: 101;
  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    height: 400px;
    background-color: #fff;
    padding-top: 80px;
    padding-right: 46px;
    padding-bottom: 50px;
    padding-left: 46px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 32px 9px rgba(0,0,0,0.33);
    box-shadow: 0px 0px 32px 9px rgba(0,0,0,0.33);
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  &__btn {
    position: absolute;
    right: 23px;
    top: 23px;
    background: inherit;
    cursor: pointer;
  }
  &__title {
    font-family: $font-Cormorant-Regular;
    font-size: 36px;
    line-height: 44px;
    font-weight: 400;
    margin-bottom: 5px!important;
  }
  &__desc {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px!important;
    @media (max-width: 600px) {
      margin-bottom: 15px;
    }
  }
  p {
    color: $gray-additional;
    margin-bottom: 50px;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
}

.btn-to-top {
    position: absolute;
  bottom: 70px;
  right: 80px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background:  #303030;
  border: 1px solid #535353;
  transition: 1s all;
  transform: translate(50%,50%);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1700px) {
    bottom: 50px;
  }
  @media (max-width: 1420px) {
    bottom: 33px;
  }
  @media (max-width: 768px) {
    bottom: 163px;
  }
  &:hover {
    width: 75px;
    height: 75px;
    svg {
      path {
        fill: white;
      }
    }
  }
}
.container-full__img--dark {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 2.26%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
  
  @media  (max-width: 768px) {
    display: none;
  }
  }
}