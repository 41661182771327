$main-color: #A07759;
$secondary-color: #80583A;
$additional-color: #D9BEA4;
$black-color: #2F2F2F;
$gray-color: #C5C5C5;
$gray-additional: #808080;
$white: #ffffff;
$error: #961818;

$font-CormorantGaramond-Bold: CormorantGaramond-Bold;
$font-Cormorant-Regular: Cormorant-Regular;
$font-Cormorant-Bold: Cormorant-Bold;
$font-OpenSans-Bold: OpenSans-Bold;
$font-OpenSans-Regular: OpenSans-Regular;
$font-OpenSans-Light: OpenSans-Light;
$font-OpenSans-Semibold: OpenSans-Semibold;
