.btn {
  width: 260px;
  height: 60px;
  background-color: $main-color;
  line-height: 25px;
  text-align: center;
  color: $white;
  border: none;
  font-size: 18px;
  &:hover {
    color: $main-color;
    background-color: $white;
    border: 2px solid $main-color;
  }
  @media (max-width: 1024px) {
    width: 240px;
    font-size: 16px;
    line-height: 19px;
  }
}
.btn--secondary {
  background-color: $secondary-color;
}

.btn-send {
  width: 60px;
  height: 60px;
  background-color: $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $additional-color;
  }
}