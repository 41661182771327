.site-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: -100px auto 0;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../img/img-error-left.svg") no-repeat;
    z-index: 1;
    background-position: center left;
  }
  &:after {
    content: "";
    position: absolute;
    top: -40px;
    right: 0;
    width: 40%;
    height: 100%;
    background: url("../../img/img-error-right.svg") no-repeat;
    z-index: 1;
    @media (max-width: 1450px) {
      width: 58%;
    }
  }
  &__title {
    display: flex;
    margin-bottom: 30px;
    @media (max-width: 1700px) {
      margin-bottom: 20px;
    }
    b {
      font-family: $font-Cormorant-Regular;
      font-weight: 500;
      font-size: 288px;
      line-height: 349px;
      color: $main-color;
      @media (max-width: 1700px) {
        font-size: 220px;
        line-height: 266px;
      }
      @media (max-width: 768px) {
        font-size: 144px;
        line-height: 174px;

      }
    }
    span {
      display: flex;
      align-items: flex-end;
      margin-left: 46px;
      margin-right: 46px;
      @media(max-width: 1700px) {
        width: 110px;
        height: 182px;
        align-items: unset;
        margin-top: 30px;
        margin-left: 35px;
        margin-right: 35px;
      }
      @media(max-width: 768px) {
        width: 70px;
        height: 115px;
        margin-top: -25px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  .alert-danger {
    font-size: 54px;
    line-height: 65px;
    font-family: $font-CormorantGaramond-Bold;
    margin-bottom: 30px;
    @media(max-width: 1700px) {
      font-size: 48px;
      line-height: 58px;
    }
    @media(max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
    }
  }
  &__info {
    color: $gray-additional;
    margin-bottom: 80px;
    width: 500px;
    text-align: center;
    @media(max-width: 1700px) {
      margin-bottom: 40px;
    }
    @media(max-width: 768px) {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  &__btn {
    width: 320px;
    height: 60px;
    background-color: #fff;
    color: $main-color;
    border: 1px solid #A07759;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s all ease-in-out;
    z-index: 3;
    &:hover {
      background-color: $main-color;
      color: $black-color;
    }
    @media(max-width: 768px) {
      width: 220px;
      height: 40px;
    }
  }
}